import { Routes } from 'common/enums'
import { IStoreCache } from 'common/types/auth_provider'
import { TagForm } from 'common/types/form/tag'
import { Tag } from 'common/types/tag'
import { getErrorMessage } from 'common/utils'
import { History } from 'history'
import { reloadCachedTags } from 'providers/AuthProvider'
import { create, remove, update } from 'repositories/tag'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * タグを作成 or 更新する
 */
export const saveTag = async (
  storeCache: IStoreCache,
  { push }: History,
  isCreate: boolean,
  data: TagForm,
  id: number | null
): Promise<void> => {
  try {
    const { name }: Tag = isCreate
      ? await create(storeCache.accesstoken, data)
      : await update(storeCache.accesstoken, data, id!)
    await reloadCachedTags(storeCache)

    alertService.show(true, `${name}の保存が完了しました。`)
    push(Routes.Tag)
  } catch (error) {
    getErrorMessage(error)
  }
}

/**
 * タグを削除する
 */
export const deleteTag = (storeCache: IStoreCache, { name, id }: Tag): void => {
  try {
    modalService.show(`${name}を削除しますか？`, async () => {
      await remove(storeCache.accesstoken, id, storeCache.user!.id)
      await reloadCachedTags(storeCache)
      alertService.show(true, `${name}の削除が完了しました。`)
    })
  } catch (error) {
    getErrorMessage(error)
  }
}
