import { Routes } from 'common/enums'
import { IStoreCache } from 'common/types/auth_provider'
import { OrganizationForm } from 'common/types/form/organization'
import { Organization } from 'common/types/organization'
import { getErrorMessage } from 'common/utils'
import { History } from 'history'
import { reloadCachedOrganizations } from 'providers/AuthProvider'
import { create, remove, update } from 'repositories/organization'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * 所属団体を作成 or 更新する
 */
export const saveOrganization = async (
  storeCache: IStoreCache,
  { push }: History,
  isCreate: boolean,
  data: OrganizationForm,
  id: number | null
): Promise<void> => {
  try {
    if (
      storeCache.organizations.some(
        ({ id: pk, name }) => pk !== id && name === data.name
      )
    )
      throw new Error('既に同じ名前の団体が登録されてます。')

    const { name }: Organization = isCreate
      ? await create(storeCache.accesstoken, data)
      : await update(storeCache.accesstoken, data, id!)
    await reloadCachedOrganizations(storeCache)

    alertService.show(true, `${name}の保存が完了しました。`)
    push(Routes.Organization)
  } catch (error) {
    getErrorMessage(error)
  }
}

/**
 * 所属団体を削除する
 */
export const deleteOrganization = (
  storeCache: IStoreCache,
  { name, id }: Organization
): void => {
  try {
    modalService.show(`${name}を削除しますか？`, async () => {
      await remove(storeCache.accesstoken, id, storeCache.user!.id)
      await reloadCachedOrganizations(storeCache)
      alertService.show(true, `${name}の削除が完了しました。`)
    })
  } catch (error) {
    getErrorMessage(error)
  }
}
