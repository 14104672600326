import { Routes } from 'common/enums'
import { Area } from 'common/types/area'
import FormCard from 'components/organisms/Admin/AreaForm/FormCard'
import React from 'react'
import { useLocation } from 'react-router'

const AreaForm: React.FC = () => {
  const { pathname, state } = useLocation<Area>()

  const isCreate = pathname === Routes.AreaCreate
  const area = state

  return <FormCard isCreate={isCreate} area={area} />
}

export default AreaForm
