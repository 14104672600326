import { Labels, Routes } from 'common/enums'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Column } from 'react-table'
import ButtonRight from 'components/atoms/Button/Right'
import OriginCard from 'components/atoms/Common/OriginCard'
import { Log } from 'common/types/log'
import { LogTable } from 'common/types/table/log'
import { getLogs, getLogsPage } from 'services/admin/log'
import { formatLogAction } from 'common/utils'
import { formatDate } from 'common/time'
import PaginationTable from 'components/atoms/Table/PaginationTable'
import { Pagination } from 'common/types/pagination'

const TableCard: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [pagination, setPagination] = useState<Pagination | null>(null)

  useEffect(() => {
    getLogs(storeCache, setPagination)
  }, [storeCache])

  const previousPage = () =>
    getLogsPage(storeCache, pagination?.previous ?? null, setPagination, true)
  const nextPage = () =>
    getLogsPage(storeCache, pagination?.next ?? null, setPagination)

  const columns: Column<LogTable>[] = useMemo(
    () => [
      { Header: Labels.ID, accessor: 'id', sortType: 'number' },
      { Header: '画面', accessor: 'page', sortType: 'basic' },
      { Header: '内容', accessor: 'action', sortType: 'basic' },
      { Header: '日時', accessor: 'created_at', sortType: 'basic' },
      { Header: 'ユーザ', accessor: 'member', sortType: 'basic' },
    ],
    []
  )
  const data: LogTable[] = useMemo(
    () =>
      pagination
        ? pagination.results.map(
            ({ id, page, action, created_at, member_obj }: Log) => ({
              id,
              page,
              action: formatLogAction(action),
              created_at: formatDate(created_at),
              member: member_obj?.username ?? '',
            })
          )
        : [],
    [pagination]
  )

  return (
    <OriginCard title="ログ">
      <ButtonRight
        history={history}
        nextPage={Routes.ManagementMenu}
        content={Labels.GoBackManagementMenu}
      />
      <PaginationTable
        columns={columns}
        data={data}
        pagination={pagination}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </OriginCard>
  )
}

export default TableCard
