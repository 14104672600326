import { Area } from 'common/types/area'
import { IAuthProvider, IStoreCache } from 'common/types/auth_provider'
import { Case } from 'common/types/case'
import { Condition } from 'common/types/condition'
import { Organization } from 'common/types/organization'
import { SupportOrganization } from 'common/types/support_organization'
import { SupportSystem } from 'common/types/support_system'
import { SystemMessage } from 'common/types/system_message'
import { Tag } from 'common/types/tag'
import React, { useContext, useEffect, useState } from 'react'
import { fetch as fetchAreas } from 'repositories/area'
import { fetch as fetchCases } from 'repositories/case'
import { fetch as fetchConditionGroups } from 'repositories/condition_group'
import { fetch as fetchConditionPatterns } from 'repositories/condition_pattern'
import { fetchMe } from 'repositories/member'
import { fetch as fetchOrganizations } from 'repositories/organization'
import { fetch as fetchSupportOrganizations } from 'repositories/support_organization'
import { fetch as fetchSupportSystems } from 'repositories/support_system'
import { fetch as fetchSystemMessage } from 'repositories/system_message'
import { fetch as fetchTags } from 'repositories/tag'

export const AuthContext = React.createContext<IAuthProvider>({
  storeCache: {
    isLoading: true,
    accesstoken: '',
    user: null,
    systemMessage: null,
    tags: [],
    areas: [],
    organizations: [],
    cases: [],
    supportSystems: [],
    supportOrganizations: [],
    conditionGroups: [],
    conditionPatterns: [],
    setStoreCache: null,
  },
})

export const useAuthContext = () => useContext(AuthContext)

export const AuthProvider = ({ children }: { children: any }): JSX.Element => {
  const [storeCache, setStoreCache] = useState<IStoreCache>({
    isLoading: true,
    accesstoken: '',
    user: null,
    systemMessage: null,
    tags: [],
    areas: [],
    organizations: [],
    cases: [],
    supportSystems: [],
    supportOrganizations: [],
    conditionGroups: [],
    conditionPatterns: [],
    setStoreCache: null,
  })

  useEffect(() => {
    ;(async () => setStoreCache(await getCache(setStoreCache)))()
  }, [])

  return (
    <AuthContext.Provider value={{ storeCache }}>
      {children}
    </AuthContext.Provider>
  )
}

const getCache = async (
  setStoreCache: React.Dispatch<React.SetStateAction<IStoreCache>>
): Promise<IStoreCache> => {
  try {
    const accesstoken = localStorage.getItem('accesstoken')
    if (!accesstoken) throw new Error()

    const [
      user,
      systemMessage,
      tags,
      areas,
      organizations,
      cases,
      supportSystems,
      supportOrganizations,
      conditionGroups,
      conditionPatterns,
    ] = await Promise.all([
      await fetchMe(accesstoken),
      await fetchSystemMessage(accesstoken),
      await fetchTags(accesstoken),
      await fetchAreas(accesstoken),
      await fetchOrganizations(accesstoken),
      await fetchCases(accesstoken),
      await fetchSupportSystems(accesstoken),
      await fetchSupportOrganizations(accesstoken),
      await fetchConditionGroups(accesstoken),
      await fetchConditionPatterns(accesstoken),
    ])
    return {
      isLoading: false,
      accesstoken,
      user,
      systemMessage,
      tags,
      areas,
      organizations,
      cases,
      supportSystems,
      supportOrganizations,
      conditionGroups,
      conditionPatterns,
      setStoreCache,
    }
  } catch (error) {
    console.log(error)

    return {
      isLoading: false,
      accesstoken: '',
      user: null,
      systemMessage: null,
      tags: [],
      areas: [],
      organizations: [],
      cases: [],
      supportSystems: [],
      supportOrganizations: [],
      conditionGroups: [],
      conditionPatterns: [],
      setStoreCache,
    }
  }
}

export const reloadCachedAccesstoken = (storeCache: IStoreCache) => {
  const accesstoken = localStorage.getItem('accesstoken')
  if (!accesstoken) return

  storeCache.setStoreCache?.({ ...storeCache, accesstoken })
}

export const reloadCachedSystemMessage = async (storeCache: IStoreCache) => {
  const systemMessage: SystemMessage | null = storeCache.accesstoken
    ? await fetchSystemMessage(storeCache.accesstoken)
    : null
  storeCache.setStoreCache?.({ ...storeCache, systemMessage })
}

export const reloadCachedTags = async (storeCache: IStoreCache) => {
  const tags: Tag[] = storeCache.accesstoken
    ? await fetchTags(storeCache.accesstoken)
    : []
  storeCache.setStoreCache?.({ ...storeCache, tags })
}

export const reloadCachedAreas = async (storeCache: IStoreCache) => {
  const areas: Area[] = storeCache.accesstoken
    ? await fetchAreas(storeCache.accesstoken)
    : []
  storeCache.setStoreCache?.({ ...storeCache, areas })
}

export const reloadCachedOrganizations = async (storeCache: IStoreCache) => {
  const organizations: Organization[] = storeCache.accesstoken
    ? await fetchOrganizations(storeCache.accesstoken)
    : []
  storeCache.setStoreCache?.({ ...storeCache, organizations })
}

export const reloadCachedCases = async (storeCache: IStoreCache) => {
  const cases: Case[] = storeCache.accesstoken
    ? await fetchCases(storeCache.accesstoken)
    : []
  storeCache.setStoreCache?.({ ...storeCache, cases })
}

export const reloadCachedSupportSystems = async (storeCache: IStoreCache) => {
  const supportSystems: SupportSystem[] = storeCache.accesstoken
    ? await fetchSupportSystems(storeCache.accesstoken)
    : []
  storeCache.setStoreCache?.({ ...storeCache, supportSystems })
}

export const reloadCachedSupportOrganizations = async (
  storeCache: IStoreCache
) => {
  const supportOrganizations: SupportOrganization[] = storeCache.accesstoken
    ? await fetchSupportOrganizations(storeCache.accesstoken)
    : []
  storeCache.setStoreCache?.({ ...storeCache, supportOrganizations })
}

export const reloadCachedConditionGroups = async (storeCache: IStoreCache) => {
  const conditionGroups: Condition[] = storeCache.accesstoken
    ? await fetchConditionGroups(storeCache.accesstoken)
    : []
  storeCache.setStoreCache?.({ ...storeCache, conditionGroups })
}

export const reloadCachedConditionPatterns = async (
  storeCache: IStoreCache
) => {
  const conditionPatterns: Condition[] = storeCache.accesstoken
    ? await fetchConditionPatterns(storeCache.accesstoken)
    : []
  storeCache.setStoreCache?.({ ...storeCache, conditionPatterns })
}

export const reloadCachedSupportSystemsAndConditionGroups = async (
  storeCache: IStoreCache
) => {
  const supportSystems: SupportSystem[] = storeCache.accesstoken
    ? await fetchSupportSystems(storeCache.accesstoken)
    : []
  const conditionGroups: Condition[] = storeCache.accesstoken
    ? await fetchConditionGroups(storeCache.accesstoken)
    : []
  storeCache.setStoreCache?.({ ...storeCache, supportSystems, conditionGroups })
}

export const reloadCachedSupportOrganizationsAndConditionGroups = async (
  storeCache: IStoreCache
) => {
  const supportOrganizations: SupportOrganization[] = storeCache.accesstoken
    ? await fetchSupportOrganizations(storeCache.accesstoken)
    : []
  const conditionGroups: Condition[] = storeCache.accesstoken
    ? await fetchConditionGroups(storeCache.accesstoken)
    : []
  storeCache.setStoreCache?.({
    ...storeCache,
    supportOrganizations,
    conditionGroups,
  })
}
