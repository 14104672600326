import { Routes } from 'common/enums'
import Alert from 'components/atoms/Alert/Alert'
import Confirm from 'components/atoms/Alert/Confirm'
import ScrollToTop from 'components/atoms/Scroll/ScrollToTop'
import { AuthProvider } from 'providers/AuthProvider'
import React from 'react'
import { Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import User from 'routes/User'
import 'styles/base.scss'
import Login from 'template/Login'
import NotFound from 'template/NotFound'
import TermsOfUse from 'template/TermsOfUse'

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />

        <AuthProvider>
          <Switch>
            <Route path={Routes.Login} component={() => <Login />} exact />
            <Route
              path={Routes.TermsOfUse}
              component={() => <TermsOfUse />}
              exact
            />
            <Route path={userPathList()} component={() => <User />} />
            <Route component={() => <NotFound />} />
          </Switch>
        </AuthProvider>
      </BrowserRouter>
      <Alert />
      <Confirm />
    </>
  )
}

const userPathList = (): Routes[] => [
  Routes.Home,
  Routes.Tag,
  Routes.TagCreate,
  Routes.TagEdit,
  Routes.Case,
  Routes.CaseShow,
  Routes.CaseEdit,
  Routes.SupportSystem,
  Routes.SupportSystemShow,
  Routes.SupportSystemCreate,
  Routes.SupportSystemEdit,
  Routes.SupportOrganization,
  Routes.SupportOrganizationCreate,
  Routes.SupportOrganizationShow,
  Routes.SupportOrganizationEdit,
  Routes.ManagementMenu,
  Routes.Member,
  Routes.MemberCreate,
  Routes.MemberEdit,
  Routes.Organization,
  Routes.OrganizationCreate,
  Routes.OrganizationEdit,
  Routes.Area,
  Routes.AreaCreate,
  Routes.AreaEdit,
  Routes.Permission,
  Routes.SystemMessageForm,
  Routes.ConditionGroup,
  Routes.ConditionGroupCreate,
  Routes.ConditionGroupEdit,
  Routes.ConditionPattern,
  Routes.ConditionPatternCreate,
  Routes.ConditionPatternEdit,
  Routes.Log,
  Routes.LoggedIn,
]

export default App
