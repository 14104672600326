import { Labels, Routes } from 'common/enums'
import { IStoreCache } from 'common/types/auth_provider'
import { Case } from 'common/types/case'
import { CaseForm } from 'common/types/form/case'
import { formatSelectOptions, getErrorMessage } from 'common/utils'
import { History } from 'history'
import { reloadCachedCases } from 'providers/AuthProvider'
import { create, remove, update } from 'repositories/case'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * 事例を作成 or 更新する
 */
export const saveCase = async (
  storeCache: IStoreCache,
  { push }: History,
  isCreate: boolean,
  data: CaseForm,
  id: number | null
): Promise<void> => {
  try {
    data.tags = formatSelectOptions(data.tags)
    data.support_organizations = formatSelectOptions(data.support_organizations)
    data.support_systems = formatSelectOptions(data.support_systems)
    const { name }: Case = isCreate
      ? await create(storeCache.accesstoken, data)
      : await update(storeCache.accesstoken, data, id!)
    await reloadCachedCases(storeCache)
    alertService.show(true, `${name}の保存が完了しました。`)
    push(Routes.Case)
  } catch (error) {
    getErrorMessage(error)
  }
}

/**
 * 事例を削除する
 */
export const deleteCase = (
  storeCache: IStoreCache,
  { name, id }: Case
): void => {
  try {
    const caseName: string = name ? name : `${Labels.CaseName}なし`
    modalService.show(`${caseName}を削除しますか？`, async () => {
      await remove(storeCache.accesstoken, id, storeCache.user!.id)
      await reloadCachedCases(storeCache)
      alertService.show(true, `${caseName}の削除が完了しました。`)
    })
  } catch (error) {
    getErrorMessage(error)
  }
}
