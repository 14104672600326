import { destroy, get, patch, post } from 'common/fetch'
import { TagForm } from 'common/types/form/tag'
import { Tag } from 'common/types/tag'

const API_BASE_URL = '/tag/'

/**
 * タグを全て取得する
 */
export const fetch = async (accesstoken: string): Promise<Tag[]> =>
  await get<Tag[]>(accesstoken, API_BASE_URL)

/**
 * タグを新規登録する
 */
export const create = async (
  accesstoken: string,
  data: TagForm
): Promise<Tag> => await post<Tag>(accesstoken, API_BASE_URL, data)

/**
 * タグを更新する
 */
export const update = async (
  accesstoken: string,
  data: TagForm,
  id: number
): Promise<Tag> => await patch<Tag>(accesstoken, `${API_BASE_URL}${id}/`, data)

/**
 * タグを削除する
 */
export const remove = async (
  accesstoken: string,
  id: number,
  member_id: number
): Promise<void> =>
  await destroy<void>(accesstoken, `${API_BASE_URL}${id}/`, { member_id })
