import { ButtonColor, Labels, Routes } from 'common/enums'
import { SupportSystemForm } from 'common/types/form/support_system'
import { SupportSystem } from 'common/types/support_system'
import {
  createMaxLengthMessage,
  createRequiredMessage,
  getTitle,
} from 'common/utils'
import OnSubmitButton from 'components/atoms/Button/OnSubmitButton'
import SmallButton from 'components/atoms/Button/SmallButton'
import OriginCard from 'components/atoms/Common/OriginCard'
import Feedback from 'components/atoms/Feedback/Feedback'
import Checkbox from 'components/atoms/Form/Checkbox/Checkbox'
import URLInput from 'components/atoms/Form/Input/URLInput'
import MultiSelect from 'components/atoms/Form/Select/MultiSelect'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Form, FormGroup, Input, Row } from 'reactstrap'
import { isNormalUserOrLess } from 'services/auth'
import {
  getOptionsGroup,
  initSupportSystem,
  saveSupportSystem,
} from 'services/user/support_system'

type TFormCard = {
  isCreate: boolean
  isShow: boolean
  supportSystem: SupportSystem
}

const FormCard: React.FC<TFormCard> = ({
  isCreate,
  isShow,
  supportSystem,
}: TFormCard) => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const defaultValues = initSupportSystem(storeCache, supportSystem, isCreate)
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SupportSystemForm>({ defaultValues })

  const isNormalUser = isNormalUserOrLess(storeCache.user)

  const { supportSystemOptions, conditionGroupOptions } = getOptionsGroup(
    storeCache,
    supportSystem
  )

  const save = (data: SupportSystemForm) =>
    saveSupportSystem(
      storeCache,
      history,
      isCreate,
      data,
      isCreate ? null : supportSystem.id
    )

  return (
    <OriginCard title={`支援制度${getTitle(isCreate, isShow)}`}>
      <Row className="justify-content-end">
        <SmallButton
          color={ButtonColor.DEFAULT}
          content={Labels.GoBack}
          onClick={() => history.goBack()}
        />
        {!isNormalUser && isShow && (
          <SmallButton
            color={ButtonColor.SECONDARY}
            content={Labels.Edit}
            onClick={() =>
              history.push(Routes.SupportSystemEdit, supportSystem)
            }
          />
        )}
      </Row>
      <Form role="form" onSubmit={handleSubmit(save)}>
        <fieldset disabled={isShow}>
          <h4>{Labels.SupportSystemName}</h4>
          <FormGroup>
            <Controller
              name="name"
              control={control}
              rules={{
                required: createRequiredMessage(Labels.SupportSystemName),
                maxLength: {
                  value: 500,
                  message: createMaxLengthMessage(
                    Labels.SupportSystemName,
                    500
                  ),
                },
              }}
              render={({ field }) => (
                <Input
                  type="text"
                  list="name"
                  className={errors.name && 'is-invalid'}
                  {...field}
                />
              )}
            />
            <datalist id="name">
              {storeCache.supportSystems.map(({ name }, i) => (
                <option key={i} value={name} />
              ))}
            </datalist>
            <Feedback error={errors.name} />
          </FormGroup>
          <h4>{Labels.Description}</h4>
          <FormGroup>
            <Controller
              name="description"
              control={control}
              rules={{
                maxLength: {
                  value: 5000,
                  message: createMaxLengthMessage(Labels.Description, 5000),
                },
              }}
              render={({ field }) => (
                <Input
                  type="textarea"
                  className={errors.description && 'is-invalid'}
                  {...field}
                />
              )}
            />
            <Feedback error={errors.description} />
          </FormGroup>
          <FormGroup>
            <h4>{Labels.OtherCombinedUnavailable}</h4>
            <Controller
              name="other_combined_unavailable"
              control={control}
              render={({ field }) => (
                <Select
                  closeMenuOnSelect={false}
                  isDisabled={isNormalUser || isShow}
                  isMulti
                  options={supportSystemOptions}
                  {...field}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <h4>{Labels.PayAmountEtc}</h4>
            <Controller
              name="pay_amount_etc"
              control={control}
              render={({ field }) => (
                <CreatableSelect
                  closeMenuOnSelect={false}
                  isDisabled={isNormalUser || isShow}
                  isClearable
                  isMulti
                  {...field}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <h4>{Labels.Application}</h4>
            <Controller
              name="application"
              control={control}
              render={({ field }) => <Input type="textarea" {...field} />}
            />
          </FormGroup>
          <FormGroup>
            <h4>{Labels.ApplicationPeriod}</h4>
            <Controller
              name="application_period"
              control={control}
              render={({ field }) => <Input type="date" {...field} />}
            />
          </FormGroup>
          <h4>{Labels.Url}</h4>
          <URLInput name="url" control={control} errors={errors} />
          <MultiSelect
            name="condition_groups"
            label={Labels.ConditionGroup}
            control={control}
            options={conditionGroupOptions}
            isDisabled={isNormalUser || isShow}
          />
          <Checkbox id="status" label={Labels.Status} register={register} />

          {!isShow && <OnSubmitButton content={Labels.Save} />}
        </fieldset>
      </Form>
    </OriginCard>
  )
}

export default FormCard
