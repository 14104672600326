import { Routes } from 'common/enums'
import {
  filterConditionGroups,
  filterSupportSystems,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/types/auth_provider'
import { HomeForm } from 'common/types/form/home'
import { SupportOrganizationForm } from 'common/types/form/support_organization'
import { SupportOrganization } from 'common/types/support_organization'
import {
  createSelectOptions,
  createSelectOptionsForCondition,
  formatSelectOptions,
  getErrorMessage,
} from 'common/utils'
import { History } from 'history'
import {
  reloadCachedSupportOrganizations,
  reloadCachedSupportOrganizationsAndConditionGroups,
} from 'providers/AuthProvider'
import { create, remove, update } from 'repositories/support_organization'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * 支援団体フォーム初期値設定
 */
export const initSupportOrganization = (
  storeCache: IStoreCache,
  so: SupportOrganization,
  isCreate: boolean
): SupportOrganizationForm =>
  isCreate
    ? {
        name: '',
        business_detail: '',
        url: '',
        email: '',
        tel: '',
        address: '',
        support_systems: [],
        condition_groups: [],
        status: true,
        note: '',
        search_criteria: {} as HomeForm,
        member_id: storeCache.user!.id,
      }
    : {
        name: so.name,
        business_detail: so.business_detail,
        url: so.url,
        email: so.email,
        tel: so.tel,
        address: so.tel,
        support_systems: createSelectOptions(
          filterSupportSystems(storeCache, so.support_systems)
        ) as any[],
        condition_groups: createSelectOptionsForCondition(
          so.condition_groups
            ? filterConditionGroups(storeCache, so.condition_groups)
            : [],
          true
        ) as any,
        status: so.status,
        note: so.note,
        search_criteria: so.search_criteria as HomeForm,
        member_id: storeCache.user!.id,
      }

/**
 * 支援団体を作成 or 更新する
 */
export const saveSupportOrganization = async (
  storeCache: IStoreCache,
  { push }: History,
  isCreate: boolean,
  data: SupportOrganizationForm,
  id: number | null
): Promise<void> => {
  try {
    if (
      storeCache.supportOrganizations.some(
        ({ id: pk, name }) => pk !== id && name === data.name
      )
    )
      throw new Error('既に同じ名前の支援団体が登録されてます。')

    data.support_systems = formatSelectOptions(data.support_systems)
    data.condition_groups = formatSelectOptions(data.condition_groups)
    const { name }: SupportOrganization = isCreate
      ? await create(storeCache.accesstoken, data)
      : await update(storeCache.accesstoken, data, id!)

    await reloadCachedSupportOrganizationsAndConditionGroups(storeCache)

    alertService.show(true, `${name}の保存が完了しました。`)
    push(Routes.SupportOrganization)
  } catch (error) {
    getErrorMessage(error)
  }
}

/**
 * 支援制度を削除する
 */
export const deleteSupportOrganization = (
  storeCache: IStoreCache,
  { name, id }: SupportOrganization
): void => {
  try {
    modalService.show(`${name}を削除しますか？`, async () => {
      await remove(storeCache.accesstoken, id, storeCache.user!.id)
      await reloadCachedSupportOrganizations(storeCache)
      alertService.show(true, `${name}の削除が完了しました。`)
    })
  } catch (error) {
    getErrorMessage(error)
  }
}
