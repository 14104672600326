import { Routes } from 'common/enums'
import { SupportOrganization } from 'common/types/support_organization'
import FormCard from 'components/organisms/User/SupportOrganizationForm/FormCard'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router'
import { isNormalUserOrLess } from 'services/auth'

const SupportOrganizationForm: React.FC = () => {
  const { user } = useContext(AuthContext).storeCache
  const { replace } = useHistory()
  const { pathname, state } = useLocation()

  const isShow = pathname === Routes.SupportOrganizationShow

  if (isNormalUserOrLess(user) && !isShow) replace(Routes.Home)

  return (
    <FormCard
      isCreate={pathname === Routes.SupportOrganizationCreate}
      isShow={isShow}
      supportOrganization={state as SupportOrganization}
    />
  )
}

export default SupportOrganizationForm
