import { Routes } from 'common/enums'
import { Tag } from 'common/types/tag'
import FormCard from 'components/organisms/User/TagForm/FormCard'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router'
import { isNormalUserOrLess } from 'services/auth'

const TagForm: React.FC = () => {
  const { user } = useContext(AuthContext).storeCache
  const { replace } = useHistory()
  const location = useLocation()

  if (isNormalUserOrLess(user)) replace(Routes.Home)

  const isCreate = location.pathname === Routes.TagCreate
  const tag = location.state as Tag

  return <FormCard isCreate={isCreate} tag={tag} />
}

export default TagForm
