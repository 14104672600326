import React from 'react'

type TFeedback = {
  error: any
}

const Feedback: React.FC<TFeedback> = ({ error }: TFeedback) => {
  if (!error) return <></>

  return <div style={{ color: 'red' }}>※{error.message}</div>
}

export default Feedback
