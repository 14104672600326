import { Pagination as TPagination } from 'common/types/pagination'
import React from 'react'
import { Column, HeaderGroup, useSortBy, useTable } from 'react-table'
import { Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap'
import 'styles/standardtable.scss'

type Props = {
  columns: Column<any>[]
  data: readonly any[]
  pagination: TPagination | null
  previousPage: () => void
  nextPage: () => void
}

const PaginationTable: React.FC<Props> = ({
  columns,
  data,
  pagination,
  previousPage,
  nextPage,
}: Props) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<any>({ columns, data }, useSortBy)

  return (
    <>
      <Table
        className="align-items-center table-bordered table-hover"
        responsive
        {...getTableProps()}
      >
        <thead className="thead-light">
          {headerGroups.map(({ getHeaderGroupProps, headers }) => (
            <tr {...getHeaderGroupProps()}>
              {headers.map((column) => (
                <th
                  scope="col"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>{sortStr(column)}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <th scope="row" {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </th>
                ))}
              </tr>
            )
          })}
        </tbody>
      </Table>

      <Pagination aria-label="Page navigation example" className="mt-3">
        <PaginationItem>
          <PaginationLink previous onClick={previousPage} />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink next onClick={nextPage} />
        </PaginationItem>
      </Pagination>
      <div>
        {data.length}件/{pagination?.count ?? 0}件
      </div>
    </>
  )
}

const sortStr = ({ isSorted, isSortedDesc }: HeaderGroup<any>): string =>
  isSorted ? (isSortedDesc ? ' 🔽' : ' 🔼') : ''

export default PaginationTable
