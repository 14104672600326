import { Routes } from 'common/enums'
import { IStoreCache } from 'common/types/auth_provider'
import { MemberForm } from 'common/types/form/member'
import { Member } from 'common/types/member'
import { getErrorMessage, zeroIfDelete } from 'common/utils'
import { History } from 'history'
import { create, fetchUsers, remove, update } from 'repositories/member'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * メンバーを全て取得する
 */
export const getMembers = async (
  accesstoken: string,
  setUsers: React.Dispatch<React.SetStateAction<Member[]>>
): Promise<void> => {
  try {
    setUsers(await fetchUsers(accesstoken))
  } catch (error) {
    getErrorMessage(error)
  }
}

/**
 * メンバーを作成 or 更新する
 */
export const saveMember = async (
  { accesstoken }: IStoreCache,
  { push }: History,
  isCreate: boolean,
  data: MemberForm,
  id: number | null
): Promise<void> => {
  try {
    if (data.password !== data.password_confirm)
      throw new Error('パスワードが一致しません。')

    zeroIfDelete(data, 'area')
    zeroIfDelete(data, 'organization')
    const { username }: Member = isCreate
      ? await create(accesstoken, data)
      : await update(accesstoken, data, id!)

    alertService.show(true, `${username}の保存が完了しました。`)
    push(Routes.Member)
  } catch (error) {
    getErrorMessage(error)
  }
}

/**
 * 支援制度を削除する
 */
export const deleteMember = (
  { accesstoken, user }: IStoreCache,
  { username, id }: Member,
  setUsers: React.Dispatch<React.SetStateAction<Member[]>>
): void => {
  try {
    modalService.show(`${username}を削除しますか？`, async () => {
      await remove(accesstoken, id, user!.id)
      alertService.show(true, `${username}の削除が完了しました。`)
      setUsers(await fetchUsers(accesstoken))
    })
  } catch (error) {
    getErrorMessage(error)
  }
}
