import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Routes } from 'common/enums'
import OriginCard from 'components/atoms/Common/OriginCard'
import { AuthContext } from 'providers/AuthProvider'

const NotFound: React.FC = () => {
  const { accesstoken } = useContext(AuthContext).storeCache
  const history = useHistory()

  if (accesstoken) history.replace(Routes.Home)

  return (
    <OriginCard title="404エラー" marginTop col={8}>
      <p>お探しのページは見つかりませんでした。</p>
      <Link to={Routes.Login}>ログイン画面へ</Link>
    </OriginCard>
  )
}

export default NotFound
