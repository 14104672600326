import { destroy, get, patch, post } from 'common/fetch'
import { Condition } from 'common/types/condition'

const API_BASE_URL = '/condition_pattern/'

/**
 * 検索条件パターンを全て取得する
 */
export const fetch = async (
  accesstoken: string,
  member_id: number | null = null
): Promise<Condition[]> =>
  await get<Condition[]>(accesstoken, API_BASE_URL, { member_id })

/**
 * 検索条件パターンを作成する
 */
export const create = async (
  accesstoken: string,
  data: Condition
): Promise<Condition> => await post<Condition>(accesstoken, API_BASE_URL, data)

/**
 * 検索条件パターンを更新する
 */
export const update = async (
  accesstoken: string,
  data: Condition,
  id: number
): Promise<Condition> =>
  await patch<Condition>(accesstoken, `${API_BASE_URL}${id}/`, data)

/**
 * 検索条件パターンを削除する
 */
export const remove = async (
  accesstoken: string,
  id: number,
  member_id: number
): Promise<void> =>
  await destroy<void>(accesstoken, `${API_BASE_URL}${id}/`, { member_id })
