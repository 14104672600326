import React, { useState } from 'react'
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputProps,
} from 'reactstrap'

export const SecretInput: React.FC<InputProps> = (props) => {
  const [isShow, setIsShow] = useState(false)
  const inputStyle = {
    '-webkit-text-security': isShow ? 'none' : 'disc',
  } as React.CSSProperties
  return (
    <InputGroup className="mb-3">
      <Input style={inputStyle} {...props} />
      <InputGroupAddon addonType="append">
        <Button color="primary" outline onClick={() => setIsShow(!isShow)}>
          {isShow ? '非表示' : '表示'}
        </Button>
      </InputGroupAddon>
    </InputGroup>
  )
}
