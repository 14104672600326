import { destroy, get, patch, post } from 'common/fetch'
import { MemberForm } from 'common/types/form/member'
import { Member } from 'common/types/member'

const API_BASE_URL = '/member/'

/**
 * メンバーを全て取得する
 */
export const fetchUsers = async (accesstoken: string): Promise<Member[]> =>
  await get<Member[]>(accesstoken, API_BASE_URL)

/**
 * 認証し操作しているメンバー（自分）を取得する
 */
export const fetchMe = async (accesstoken: string): Promise<Member> =>
  await get<Member>(accesstoken, '/auth/users/me/')

/**
 * メンバーを作成する
 */
export const create = async (
  accesstoken: string,
  data: MemberForm
): Promise<Member> => await post<Member>(accesstoken, API_BASE_URL, data)

/**
 * メンバーを更新する
 */
export const update = async (
  accesstoken: string,
  data: MemberForm,
  id: number
): Promise<Member> =>
  await patch<Member>(accesstoken, `${API_BASE_URL}${id}/`, data)

/**
 * 支援制度を削除する
 */
export const remove = async (
  accesstoken: string,
  id: number,
  member_id: number
): Promise<void> =>
  await destroy<void>(accesstoken, `${API_BASE_URL}${id}/`, { member_id })
