import { post } from 'common/fetch'
import { SimpleJWT } from 'common/types/simple_jwt'

/**
 * JWTを取得する
 */
export const createJWT = async (
  username: string,
  password: string
): Promise<SimpleJWT> =>
  await post<SimpleJWT>('', '/auth/jwt/create/', { username, password })
