import { Routes } from 'common/enums'
import {
  filterAreas,
  filterSupportOrganizations,
  filterSupportSystems,
  filterTags,
  findConditionGroup,
  findConditionPattern,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/types/auth_provider'
import { Condition as ConditionType } from 'common/types/condition'
import { ConditionItem } from 'common/types/form/condition_pattern'
import { convertConditionTitle, getSearchLabel } from 'common/utils'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'

type TCondition = {
  storeCache: IStoreCache
  isConditionGroup: boolean
  condition: ConditionType
  onEdit: () => void
  onRemove: () => void
}

const Condition: React.FC<TCondition> = ({
  storeCache,
  isConditionGroup,
  condition: { id, name, query, support_systems, support_organizations },
  onEdit,
  onRemove,
}: TCondition) => (
  <Card className="card-frame mb-2">
    <CardBody>
      <Row>
        <Col sm={9}>
          <h2>
            {name ? name : `${convertConditionTitle(isConditionGroup)}${id}`}
          </h2>
        </Col>
        <Col sm={3}>
          <Button color="info" onClick={onEdit}>
            編集
          </Button>
          <Button color="danger" onClick={onRemove}>
            削除
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>{query.map((q: ConditionItem) => queryDetail(storeCache, q))}</Col>
      </Row>
      {support_systems && support_systems.length > 0 && (
        <div className="mt-5">
          <h4>紐づけされている支援制度</h4>
          <ul>
            {filterSupportSystems(storeCache, support_systems).map((ss) => (
              <li>
                <Link to={{ pathname: Routes.SupportSystemEdit, state: ss }}>
                  {ss.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      {support_organizations && support_organizations.length > 0 && (
        <div className="mt-5">
          <h4>紐づけされている支援団体</h4>
          <ul>
            {filterSupportOrganizations(storeCache, support_organizations).map(
              (so) => (
                <li>
                  <Link
                    to={{ pathname: Routes.SupportOrganizationEdit, state: so }}
                  >
                    {so.name}
                  </Link>
                </li>
              )
            )}
          </ul>
        </div>
      )}
    </CardBody>
  </Card>
)

const queryDetail = (
  storeCache: IStoreCache,
  { label, content, connection }: ConditionItem
): JSX.Element => {
  const labelStr = getSearchLabel(label)
  const contentStr = convertContent(storeCache, label, content)
  return (
    <>
      <div>
        {labelStr} : {contentStr}
      </div>
      {connection && <div className="my-3">{connection}</div>}
    </>
  )
}

const convertContent = (
  storeCache: IStoreCache,
  label: string,
  content: any
): string | JSX.Element => {
  switch (label) {
    case 'url':
      return content
    case 'support_count':
      return `${content}人`
    case 'recipient_qualification_person_income':
    case 'support_obligation_person_income':
      return `${content}万円`
    case 'parents_which_severe_fault':
    case 'other_children_fault':
    case 'other_parent_fault':
    case 'is_single_parent':
    case 'is_parents_except_raising_child':
    case 'is_family_except_raising_child':
    case 'is_abnormal':
    case 'is_livelihood_subsidies':
    case 'is_raising_child_person_taxation':
      return content ? 'はい' : 'いいえ'
    case 'condition_groups':
      const conditionGroup = findConditionGroup(storeCache, Number(content))
      const conditionGroupName = conditionGroup
        ? conditionGroup.name
        : `${convertConditionTitle(true)}${content}`
      return (
        <Link
          to={{
            pathname: Routes.ConditionGroupEdit,
            state: conditionGroup,
          }}
        >
          {conditionGroupName}
        </Link>
      )
    case 'condition_patterns':
      const conditionPattern = findConditionPattern(storeCache, Number(content))
      const conditionPatternName = conditionPattern
        ? conditionPattern.name
        : `${convertConditionTitle(false)}${content}`
      return (
        <Link
          to={{
            pathname: Routes.ConditionPatternEdit,
            state: conditionPattern,
          }}
        >
          {conditionPatternName}
        </Link>
      )
    case 'tags':
      return filterTags(storeCache, content)
        .map(({ name }) => name)
        .join(',')
    case 'parent_residence':
    case 'child_residence':
      return filterAreas(storeCache, content)
        .map(({ name }) => name)
        .join(',')
    default:
      return content.join(',')
  }
}

export default Condition
