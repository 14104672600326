import { Labels, SearchLabels } from 'common/enums'
import { HomeForm } from 'common/types/form/home'
import { SecretInput } from 'components/atoms/Form/Input/SecretInput'
import React from 'react'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'

type TIncome = {
  control: Control<HomeForm, object>
  register: UseFormRegister<HomeForm>
}

const Income: React.FC<TIncome> = ({ control, register }: TIncome) => {
  const calculationIncome = () =>
    window.open('https://tax.mykomon.com/tool-nen2020.html', '_blank')

  return (
    <Row className="mb-3">
      <Col sm="12">
        <h4>
          <i className="fas fa-square-full" />
          {SearchLabels.Income}
        </h4>
        <FormGroup className="d-flex align-items-center my-4" row>
          <Col sm={5}>
            <Label>{SearchLabels.RecipientQualificationPersonIncome}</Label>
            <Controller
              name="recipient_qualification_person_income"
              control={control}
              render={({ field }) => (
                <SecretInput type="number" min="0" {...field} />
              )}
            />
          </Col>
          <Col sm={1}>{Labels.ThousandYen}</Col>
          <Col sm={6}>
            <Button color="info" onClick={() => calculationIncome()}>
              収入から所得を算出
            </Button>
          </Col>
        </FormGroup>
        <FormGroup className="d-flex align-items-center my-4" row>
          <Col sm={5}>
            <Label>{SearchLabels.SupportObligationPersonIncome}</Label>
            <Controller
              name="support_obligation_person_income"
              control={control}
              render={({ field }) => (
                <SecretInput type="number" min="0" {...field} />
              )}
            />
          </Col>
          <Col sm={1}>{Labels.ThousandYen}</Col>
        </FormGroup>
        <div className="my-4">
          <FormGroup check inline className="mr-3">
            <div className="custom-control custom-checkbox">
              <input
                {...register('abnormal')}
                className="custom-control-input"
                id="abnormal"
                type="checkbox"
              />
              <label className="custom-control-label" htmlFor="abnormal">
                {SearchLabels.Abnormal}
              </label>
            </div>
          </FormGroup>
          <FormGroup check inline className="mr-3">
            <div className="custom-control custom-checkbox">
              <input
                {...register('livelihood_subsidies')}
                className="custom-control-input"
                id="livelihood_subsidies"
                type="checkbox"
              />
              <label
                className="custom-control-label"
                htmlFor="livelihood_subsidies"
              >
                {SearchLabels.LivelihoodSubsidies}
              </label>
            </div>
          </FormGroup>
        </div>
      </Col>
    </Row>
  )
}

export default Income
