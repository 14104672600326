import { IStoreCache } from 'common/types/auth_provider'
import { Permission } from 'common/types/permission'
import { getErrorMessage } from 'common/utils'
import { fetch } from 'repositories/permission'
import { isNormalUserOrLess } from 'services/auth'

/**
 * 権限を全て取得する
 */
export const getPermissions = async (
  { user, accesstoken }: IStoreCache,
  setPermissions: React.Dispatch<React.SetStateAction<Permission[]>>
): Promise<void> => {
  try {
    setPermissions(
      await fetch(accesstoken, !isNormalUserOrLess(user) ? user!.id : null)
    )
  } catch (error) {
    getErrorMessage(error)
  }
}
