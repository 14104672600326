import { SEARCH_LABELS } from 'common/const'
import {
  filterAreas,
  filterTags,
  findConditionGroup,
  findConditionPattern,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/types/auth_provider'
import { Condition } from 'common/types/condition'
import { OptionsOrGroups } from 'react-select'
import { alertService } from 'services/alert'
import { LogActionType } from './enums'

/**
 * react-selectでのoptionを文字列の配列の変換する
 */
export const formatSelectOptions = (
  selectOptions: OptionsOrGroups<string, any>
): string[] => selectOptions.map(({ value }) => value)

/**
 * ２つのradioボタンにて文字列を真偽値に変換する
 */
export const formatYesNo = (boolStr: 'true' | 'false'): boolean =>
  boolStr === 'true' ? true : false

/**
 * react-selectでのoptionを生成する
 */
export const createSelectOption = ({
  id,
  name,
}: {
  id: number
  name: string
}): any => ({ value: id, label: name })

/**
 * react-selectでのoptionを複数生成する
 */
export const createSelectOptions = (
  array: any[]
): OptionsOrGroups<string, any> =>
  array.map(({ id, name }: { id: number; name: string }) => ({
    value: id,
    label: name,
  }))

/**
 * react-selectでのoptionを複数生成する（DB専用）
 */
export const createSelectOptionsForLabel = (array: string[]): any[] =>
  array.map((str: string) => ({ value: str, label: str }))

/**
 * react-selectでのoptionを複数生成する（配列用）
 */
export const createSelectOptionsForArr = (
  array: any[]
): OptionsOrGroups<string, any> =>
  array.map((anyData: any) => ({ value: anyData, label: anyData }))

const createSelectOptionsCondition = (
  { id, name }: Condition,
  isConditionGroup: boolean
): any => {
  const label = name ?? `${convertConditionTitle(isConditionGroup)}${id}`
  return { value: id, label }
}

/**
 * react-selectでのoptionを複数生成する（検索条件グループ用）
 */
export const createSelectOptionsForCondition = (
  conditions: Condition[],
  isConditionGroup: boolean
): OptionsOrGroups<string, any> =>
  conditions.map((c) => createSelectOptionsCondition(c, isConditionGroup))

/**
 * 扶養人数・所得上限の配列を整形する
 */
export const formatIncomeMax = (incomeMax: any[]): any[] =>
  incomeMax.map((i) => {
    delete i.id
    i.supportCount = Number(i.supportCount)
    i.incomeMax = Number(i.incomeMax)
    return i
  })

/**
 * ログの内容を変換する
 */
export const formatLogAction = (action: LogActionType): string => {
  switch (action) {
    case LogActionType.CREATE:
      return '新規登録'
    case LogActionType.UPDATE:
      return '更新'
    case LogActionType.DESTROY:
      return '削除'
    default:
      return ''
  }
}

/**
 * get title
 */
export const getTitle = (isCreate: boolean, isShow: boolean): string => {
  if (isCreate) return '新規登録'
  if (isShow) return '詳細'
  return '編集'
}

/**
 * 値が0だったらNullに変換する
 */
export const zeroIfDelete = (obj: any, key: string): void => {
  if (obj[key] !== 0) return
  delete obj[key]
}

/**
 * バッジ文字列を取得 or 生成する
 */
export const getOrGenerateBadgeStr = (anyData: any): string => {
  switch (typeof anyData) {
    case 'boolean':
      return anyData ? 'チェックあり' : 'チェックなし'
    case 'number':
      return String(anyData)
    default:
      return anyData ? anyData : '空白'
  }
}

/**
 * 登録状況を文字列に変換する
 */
export const convertRegisterStatus = (registerStatus: number): string =>
  !registerStatus ? '仮登録' : '本登録'

/**
 * 条件を文字列に変換する
 */
export const convertCondition = (condition: boolean): string =>
  !condition ? '不問' : '必須'

/**
 * 検索条件のグループかパターンかで文字列に変換する
 */
export const convertConditionTitle = (isConditionGroup: boolean): string =>
  `条件${isConditionGroup ? 'グループ' : 'パターン'}`

/**
 * 必須のフィールドに対するメッセージを生成する
 */
export const createRequiredMessage = (field: string): string =>
  `${field}の入力は必須です。`

/**
 * 文字数の最大数上限のフィールドに対するメッセージを生成する
 */
export const createMaxLengthMessage = (
  field: string,
  maxLength: number
): string => `${field}は最大${maxLength}文字まで入力可能です。`

/**
 * 形式が異なる場合に対するメッセージを生成する
 */
export const createRegExpMessage = (field: string): string =>
  `${field}の形式で入力してください。`

/**
 * 当該の検索ラベルを取得する
 */
export const getSearchLabel = (key: string): string =>
  (SEARCH_LABELS[key] as any) ?? ''

/**
 * エラーメッセージを識別し返却する
 */
export const getErrorMessage = (error: any): void => {
  let msg: string = ''
  if ('response' in error) {
    if (error.response.status === 400) {
      Object.keys(error.response.data).forEach(
        (data) => (msg = `${data}: ${error.response.data[data][0]}`)
      )
    } else {
      switch (error.response.data.detail) {
        case 'No active account found with the given credentials':
          msg = 'メールアドレスもしくはパスワードが間違っています。'
          break
        case 'Given token not valid for any token type':
          localStorage.removeItem('accesstoken')
          localStorage.removeItem('refreshtoken')
          msg = '認証期限が過ぎています。再度ログインしてください。'
          break
        default:
          msg = error.message
          break
      }
    }
  } else {
    msg = error.message
  }
  alertService.show(false, msg)
  console.error(error)
}

/**
 * 検索条件の内容を変換する
 */
export const generateContent = (
  storeCache: IStoreCache,
  label: string,
  content: any
): any => {
  switch (label) {
    case 'parents_which_severe_fault':
    case 'other_children_fault':
    case 'other_parent_fault':
    case 'is_single_parent':
    case 'is_parents_except_raising_child':
    case 'is_family_except_raising_child':
    case 'is_abnormal':
    case 'is_livelihood_subsidies':
    case 'is_raising_child_person_taxation':
      return content ?? false
    case 'condition_groups':
      return createSelectOption(findConditionGroup(storeCache, Number(content)))
    case 'condition_patterns':
      return createSelectOption(
        findConditionPattern(storeCache, Number(content))
      )
    case 'tags':
      return createSelectOptions(filterTags(storeCache, content))
    case 'parent_residence':
    case 'child_residence':
      return createSelectOptions(filterAreas(storeCache, content))
    case 'age':
    case 'school_attendance':
    case 'physical_disability_certificate_child':
    case 'physical_disability_certificate_parent':
    case 'raising_child_handbook_child':
    case 'raising_child_handbook_parent':
    case 'mental_disability_certificate_child':
    case 'mental_disability_certificate_parent':
    case 'sp_child_allowance_support_receipt':
    case 'keyword':
      return createSelectOptionsForLabel(content)
    default:
      return content
  }
}

/**
 * 検索条件の内容を変換する
 */
export const generateYesNoStr = (label: string, isYes: boolean): string => {
  switch (label) {
    case 'parents_which_severe_fault':
      return isYes
        ? '父母どちらかが重度の障害を持っている'
        : '父母どちらかが重度の障害を持っていない'
    case 'other_children_fault':
      return isYes
        ? '子どもの障がいの程度その他を持っている'
        : '子どもの障がいの程度その他を持っていない'
    case 'other_parent_fault':
      return isYes
        ? '親の障がいの程度その他を持っている'
        : '親の障がいの程度その他を持っていない'
    case 'is_single_parent':
      return isYes
        ? 'ひとり親（母子家庭、父子家庭）である'
        : 'ひとり親（母子家庭、父子家庭）ではない'
    case 'is_parents_except_raising_child':
      return isYes ? '父母以外が養育である' : '父母以外が養育ではない'
    case 'is_family_except_raising_child':
      return isYes
        ? '養育者以外に同居家族がいる'
        : '養育者以外に同居家族がいない'
    case 'is_abnormal':
      return isYes
        ? '家計急変・失業等に該当する'
        : '家計急変・失業等に該当しない'
    case 'is_livelihood_subsidies':
      return isYes ? '生活保護を受けている' : '生活保護を受けていない'
    case 'is_raising_child_person_taxation':
      return isYes
        ? '道府県民税・市町村民税の所得割非課税（0円）に該当する'
        : '道府県民税・市町村民税の所得割非課税（0円）に該当しない'
    default:
      return ''
  }
}
