import React from 'react'
import { Badge } from 'reactstrap'

type TNomal = {
  color: string
  content: string
}

const Nomal: React.FC<TNomal> = ({ color, content }: TNomal) => (
  <Badge className="mr-1" color={color}>
    {content}
  </Badge>
)

export default Nomal
