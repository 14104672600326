import { destroy, get, patch, post } from 'common/fetch'
import { HomeForm } from 'common/types/form/home'
import { SupportSystemForm } from 'common/types/form/support_system'
import { SupportSystem } from 'common/types/support_system'

const API_BASE_URL = '/support_system/'

/**
 * 支援制度を全て取得する
 */
export const fetch = async (
  accesstoken: string,
  data?: HomeForm
): Promise<SupportSystem[]> =>
  await get<SupportSystem[]>(accesstoken, API_BASE_URL, data)

/**
 * 支援制度を新規登録する
 */
export const create = async (
  accesstoken: string,
  data: SupportSystemForm
): Promise<SupportSystem> =>
  await post<SupportSystem>(accesstoken, API_BASE_URL, data)

/**
 * 支援制度を更新する
 */
export const update = async (
  accesstoken: string,
  data: SupportSystemForm,
  id: number
): Promise<SupportSystem> =>
  await patch<SupportSystem>(accesstoken, `${API_BASE_URL}${id}/`, data)

/**
 * 支援制度を削除する
 */
export const remove = async (
  accesstoken: string,
  id: number,
  member_id: number
): Promise<void> =>
  await destroy<void>(accesstoken, `${API_BASE_URL}${id}/`, { member_id })
