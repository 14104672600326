import React from 'react'
import { NavLink as NavLinkRRD } from 'react-router-dom'
import { NavItem, NavLink } from 'reactstrap'
import 'styles/itemlink.scss'

type TItemLink = {
  setCollapseOpen: React.Dispatch<React.SetStateAction<boolean>>
  nextPage: string
  content: string
  asNewTab?: boolean
}

const ItemLink: React.FC<TItemLink> = ({
  setCollapseOpen,
  nextPage,
  content,
  asNewTab,
}: TItemLink) => (
  <NavItem>
    <NavLink
      to={nextPage}
      tag={NavLinkRRD}
      target={asNewTab ? '_blank' : undefined}
      onClick={() => setCollapseOpen(false)}
    >
      {content}
    </NavLink>
  </NavItem>
)

export default ItemLink
