import { ButtonColor, Labels, Routes } from 'common/enums'
import {
  filterSupportOrganizations,
  filterSupportSystems,
  filterTags,
} from 'common/find_store_cache'
import { Case } from 'common/types/case'
import { CaseTable } from 'common/types/table/case'
import { convertRegisterStatus, getSearchLabel } from 'common/utils'
import {
  BadgesForObject,
  NomalBadges,
} from 'components/atoms/Badge/NomalBadges'
import SmallButton from 'components/atoms/Button/SmallButton'
import OriginCard from 'components/atoms/Common/OriginCard'
import StandardTable from 'components/atoms/Table/StandardTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Column } from 'react-table'
import { deleteCase } from 'services/user/case'

const TableCard: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const { push } = useHistory()

  const columns: Column<CaseTable>[] = useMemo(
    () => [
      { Header: Labels.ID, accessor: 'id', sortType: 'basic' },
      { Header: Labels.CaseName, accessor: 'name' },
      { Header: Labels.Description, accessor: 'description' },
      { Header: Labels.Tag, accessor: 'tags' },
      {
        Header: Labels.SupportOrganizationName,
        accessor: 'support_organizations',
        disableSortBy: true,
      },
      {
        Header: Labels.SupportSystemName,
        accessor: 'support_systems',
        disableSortBy: true,
      },
      {
        Header: Labels.SearchCriteria,
        accessor: 'search_criteria',
        disableSortBy: true,
      },
      { Header: Labels.RegisterStatus, accessor: 'register_status' },
      { Header: Labels.Member, accessor: 'member' },
      { Header: Labels.Edit, accessor: 'edit', disableSortBy: true },
    ],
    []
  )
  const data: CaseTable[] = useMemo(
    () =>
      storeCache.cases.map((c: Case) => {
        return {
          id: c.id,
          name: c.name,
          description: c.description,
          tags: NomalBadges(
            filterTags(storeCache, c.tags).map(({ name }) => name),
            'info'
          ),
          support_organizations: NomalBadges(
            filterSupportOrganizations(storeCache, c.support_organizations).map(
              ({ name }) => name
            ),
            'primary'
          ),
          support_systems: NomalBadges(
            filterSupportSystems(storeCache, c.support_systems).map(
              ({ name }) => name
            ),
            'info'
          ),
          search_criteria: BadgesForObject(
            c.search_criteria,
            (key) => getSearchLabel(key),
            'warning',
            'success'
          ),
          register_status: convertRegisterStatus(c.register_status),
          member: c.member_obj?.username!,
          edit: (
            <>
              <SmallButton
                color={ButtonColor.INFO}
                content={Labels.Edit}
                onClick={() => push(Routes.CaseEdit, c)}
              />
              <SmallButton
                color={ButtonColor.DANGER}
                content={Labels.Delete}
                onClick={() => deleteCase(storeCache, c)}
              />
            </>
          ),
        }
      }),
    [storeCache, push]
  )

  return (
    <OriginCard title="事例">
      <StandardTable columns={columns} data={data} />
    </OriginCard>
  )
}

export default TableCard
