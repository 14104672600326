import { ButtonColor, Labels, Routes } from 'common/enums'
import {
  filterConditionGroups,
  filterSupportSystems,
} from 'common/find_store_cache'
import { SupportSystem } from 'common/types/support_system'
import { SupportSystemTable } from 'common/types/table/support_system'
import { convertConditionTitle } from 'common/utils'
import { NomalBadges } from 'components/atoms/Badge/NomalBadges'
import ButtonRight from 'components/atoms/Button/Right'
import SmallButton from 'components/atoms/Button/SmallButton'
import OriginCard from 'components/atoms/Common/OriginCard'
import StandardTable from 'components/atoms/Table/StandardTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'
import { isNormalUserOrLess } from 'services/auth'
import { deleteSupportSystem } from 'services/user/support_system'

const TableCard: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const isNotNormalUser = !isNormalUserOrLess(storeCache.user)

  const columns: Column<SupportSystemTable>[] = useMemo(
    () => [
      { Header: Labels.ID, accessor: 'id', sortType: 'basic' },
      { Header: Labels.SupportSystemName, accessor: 'name' },
      { Header: Labels.Description, accessor: 'description' },
      { Header: Labels.Url, accessor: 'url' },
      { Header: Labels.PayAmountEtc, accessor: 'pay_amount_etc' },
      { Header: Labels.Application, accessor: 'application' },
      { Header: Labels.ApplicationPeriod, accessor: 'application_period' },
      { Header: Labels.ConditionGroup, accessor: 'condition_groups' },
      { Header: Labels.Status, accessor: 'status' },
      { Header: Labels.Note, accessor: 'note' },
      { Header: Labels.Edit, accessor: 'edit', disableSortBy: true },
    ],
    []
  )
  const data: SupportSystemTable[] = useMemo(
    () =>
      storeCache.supportSystems.map((ss: SupportSystem) => ({
        id: ss.id,
        name: (
          <Link
            to={{
              pathname: isNotNormalUser
                ? Routes.SupportSystemEdit
                : Routes.SupportSystemShow,
              state: ss,
            }}
          >
            {ss.name}
          </Link>
        ),
        description: ss.description,
        url: ss.url,
        other_combined_unavailable: NomalBadges(
          filterSupportSystems(storeCache, ss.other_combined_unavailable).map(
            ({ name }) => name
          ),
          'primary'
        ),
        pay_amount_etc: NomalBadges(ss.pay_amount_etc, 'info'),
        application: ss.application,
        application_period: ss.application_period,
        condition_groups: NomalBadges(
          filterConditionGroups(storeCache, ss.condition_groups ?? []).map(
            ({ id, name }) => name ?? `${convertConditionTitle(true)}${id}`
          ),
          'primary'
        ),
        status: ss.status ? '表示' : '非表示',
        note: ss.note,
        edit: isNotNormalUser ? (
          <>
            <SmallButton
              color={ButtonColor.INFO}
              content={Labels.Edit}
              onClick={() => history.push(Routes.SupportSystemEdit, ss)}
            />
            <SmallButton
              color={ButtonColor.DANGER}
              content={Labels.Delete}
              onClick={() => deleteSupportSystem(storeCache, ss)}
            />
          </>
        ) : (
          <SmallButton
            color={ButtonColor.SECONDARY}
            content={Labels.Description}
            onClick={() => history.push(Routes.SupportSystemShow, ss)}
          />
        ),
      })),
    [storeCache, history, isNotNormalUser]
  )

  return (
    <OriginCard title="支援制度">
      {isNotNormalUser && (
        <ButtonRight
          history={history}
          nextPage={Routes.SupportSystemCreate}
          content={Labels.Create}
        />
      )}
      <StandardTable columns={columns} data={data} />
    </OriginCard>
  )
}

export default TableCard
