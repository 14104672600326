import { ButtonColor, Labels, Routes } from 'common/enums'
import StandardTable from 'components/atoms/Table/StandardTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Column } from 'react-table'
import { Area } from 'common/types/area'
import { AreaTable } from 'common/types/table/area'
import RightDoubleButton from 'components/atoms/Button/RightDoubleButton'
import SmallButton from 'components/atoms/Button/SmallButton'
import { deleteArea } from 'services/admin/area'
import OriginCard from 'components/atoms/Common/OriginCard'

const TableCard: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const { push } = useHistory()

  const columns: Column<AreaTable>[] = useMemo(
    () => [
      { Header: Labels.ID, accessor: 'id', sortType: 'number' },
      { Header: Labels.AreaName, accessor: 'name', sortType: 'basic' },
      { Header: Labels.SortNum, accessor: 'sort_num', sortType: 'basic' },
      { Header: Labels.Edit, accessor: 'edit', disableSortBy: true },
    ],
    []
  )
  const data: AreaTable[] = useMemo(
    () =>
      storeCache.areas.map((area: Area) => {
        return {
          id: area.id,
          name: area.name,
          sort_num: area.sort_num,
          edit: (
            <>
              <SmallButton
                color={ButtonColor.INFO}
                content={Labels.Edit}
                onClick={() => push(Routes.AreaEdit, area)}
              />
              <SmallButton
                color={ButtonColor.DANGER}
                content={Labels.Delete}
                onClick={() => deleteArea(storeCache, area)}
              />
            </>
          ),
        }
      }),
    [push, storeCache]
  )

  return (
    <OriginCard title="地域管理">
      <RightDoubleButton
        leftOnClick={() => push(Routes.ManagementMenu)}
        rightOnClick={() => push(Routes.AreaCreate)}
        leftContent={Labels.GoBackManagementMenu}
        rightContent={Labels.Create}
      />
      <StandardTable columns={columns} data={data} />
    </OriginCard>
  )
}

export default TableCard
