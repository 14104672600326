import React from 'react'
import { Table } from 'reactstrap'
import { HeaderGroup, useTable, useSortBy, Column } from 'react-table'
import 'styles/standardtable.scss'

type TStandardTable = {
  columns: Column<any>[]
  data: readonly any[]
}

const StandardTable: React.FC<TStandardTable> = ({
  columns,
  data,
}: TStandardTable) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<any>({ columns, data }, useSortBy)

  return (
    <Table
      className="align-items-center table-bordered table-hover"
      responsive
      {...getTableProps()}
    >
      <thead className="thead-light">
        {headerGroups.map(({ getHeaderGroupProps, headers }) => (
          <tr {...getHeaderGroupProps()}>
            {headers.map((column) => (
              <th
                scope="col"
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>{sortStr(column)}</span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <th scope="row" {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </th>
              ))}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

const sortStr = ({ isSorted, isSortedDesc }: HeaderGroup<any>): string =>
  isSorted ? (isSortedDesc ? ' 🔽' : ' 🔼') : ''

export default StandardTable
