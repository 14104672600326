import React from 'react'
import { Col, Row } from 'reactstrap'
import 'styles/sidebar.scss'

const Footer: React.FC = () => (
  <div className="mb-3" style={{ marginLeft: 275 }}>
    <hr />
    <Row>
      <Col sm={3}>
        <a href="https://kyuminyokin.info/" target="_blank" rel="noreferrer">
          <img
            src="https://www.janpia.or.jp/dantai/symbol/download/banner/symbol_banner_160x55.jpg"
            width="160"
            height="55"
            alt="舞い上がれ 社会を変える みんなの力 休眠預金活用事業"
          />
        </a>
      </Col>
      <Col sm={8} style={{ fontSize: 12 }}>
        本システムは、特定非営利活動法人エティックが「民間公益活動を促進するための休眠預金等に係る資金の活用に関する法律（休眠預金等活用法）」に基づく2019年度資金分配団体として選定され実施する
        <a
          href="https://www.janpia.or.jp/dantai_search/2019/dantai16.html"
          target="_blank"
          rel="noreferrer"
        >
          「子どもの未来のための協働促進助成事業」
        </a>
        による助成を受け、特定非営利活動法人岡山NPOセンターが「おかやま子ども基金(仮)」創設を核とした子どもの虐待・貧困等０を目指すオール岡山体制構築事業」の一環として制作致しました。
      </Col>
    </Row>
  </div>
)

export default Footer
