import { ButtonColor, Labels, Routes } from 'common/enums'
import { SupportOrganizationForm } from 'common/types/form/support_organization'
import { SupportOrganization } from 'common/types/support_organization'
import {
  createMaxLengthMessage,
  createRegExpMessage,
  createRequiredMessage,
  getTitle,
} from 'common/utils'
import OnSubmitButton from 'components/atoms/Button/OnSubmitButton'
import SmallButton from 'components/atoms/Button/SmallButton'
import OriginCard from 'components/atoms/Common/OriginCard'
import Feedback from 'components/atoms/Feedback/Feedback'
import Checkbox from 'components/atoms/Form/Checkbox/Checkbox'
import URLInput from 'components/atoms/Form/Input/URLInput'
import MultiSelect from 'components/atoms/Form/Select/MultiSelect'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import Select from 'react-select'
import { Form, FormGroup, Input, Row } from 'reactstrap'
import { isNormalUserOrLess } from 'services/auth'
import {
  initSupportOrganization,
  saveSupportOrganization,
} from 'services/user/support_organization'
import { getOptionsGroup } from 'services/user/support_system'

type TFormCard = {
  isCreate: boolean
  isShow: boolean
  supportOrganization: SupportOrganization
}

const FormCard: React.FC<TFormCard> = ({
  isCreate,
  isShow,
  supportOrganization,
}: TFormCard) => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const defaultValues = initSupportOrganization(
    storeCache,
    supportOrganization,
    isCreate
  )
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SupportOrganizationForm>({ defaultValues })

  const isNormalUser = isNormalUserOrLess(storeCache.user)

  const optionsGroup = getOptionsGroup(storeCache)

  const save = (data: SupportOrganizationForm) =>
    saveSupportOrganization(
      storeCache,
      history,
      isCreate,
      data,
      isCreate ? null : supportOrganization.id
    )

  return (
    <OriginCard title={`支援団体${getTitle(isCreate, isShow)}`}>
      <Row className="justify-content-end">
        <SmallButton
          color={ButtonColor.DEFAULT}
          content={Labels.GoBack}
          onClick={() => history.goBack()}
        />
        {!isNormalUser && isShow && (
          <SmallButton
            color={ButtonColor.SECONDARY}
            content={Labels.Edit}
            onClick={() =>
              history.push(Routes.SupportOrganizationEdit, supportOrganization)
            }
          />
        )}
      </Row>
      <Form role="form" onSubmit={handleSubmit(save)}>
        <fieldset disabled={isShow}>
          <h4>{Labels.SupportOrganizationName}</h4>
          <FormGroup>
            <Controller
              name="name"
              control={control}
              rules={{
                required: createRequiredMessage(Labels.SupportSystemName),
                maxLength: {
                  value: 500,
                  message: createMaxLengthMessage(
                    Labels.SupportSystemName,
                    500
                  ),
                },
              }}
              render={({ field }) => (
                <Input
                  type="text"
                  list="name"
                  className={errors.name && 'is-invalid'}
                  {...field}
                />
              )}
            />
            <datalist id="name">
              {storeCache.supportOrganizations.map(({ name }, i) => (
                <option key={i} value={name} />
              ))}
            </datalist>
            <Feedback error={errors.name} />
          </FormGroup>
          <h4>{Labels.BusinessDetail}</h4>
          <FormGroup>
            <Controller
              name="business_detail"
              control={control}
              rules={{
                maxLength: {
                  value: 5000,
                  message: createMaxLengthMessage(Labels.BusinessDetail, 5000),
                },
              }}
              render={({ field }) => (
                <Input
                  type="textarea"
                  className={errors.business_detail && 'is-invalid'}
                  {...field}
                />
              )}
            />
            <Feedback error={errors.business_detail} />
          </FormGroup>
          <h4>{Labels.Email}</h4>
          <FormGroup>
            <Controller
              name="email"
              control={control}
              rules={{
                maxLength: {
                  value: 254,
                  message: createMaxLengthMessage(Labels.Email, 254),
                },
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: createRegExpMessage(Labels.Email),
                },
              }}
              render={({ field }) => (
                <Input
                  type="email"
                  className={errors.email && 'is-invalid'}
                  {...field}
                />
              )}
            />
            <Feedback error={errors.email} />
          </FormGroup>
          <h4>{Labels.Tel}</h4>
          <FormGroup>
            <Controller
              name="tel"
              control={control}
              render={({ field }) => (
                <Input
                  type="tel"
                  className={errors.tel && 'is-invalid'}
                  {...field}
                />
              )}
            />
          </FormGroup>
          <h4>{Labels.Address}</h4>
          <FormGroup>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  className={errors.address && 'is-invalid'}
                  {...field}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <h4>{Labels.SupportSystemName}</h4>
            <Controller
              name="support_systems"
              control={control}
              render={({ field }) => (
                <Select
                  closeMenuOnSelect={false}
                  isDisabled={isNormalUser || isShow}
                  isMulti
                  options={optionsGroup.supportSystemOptions}
                  {...field}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <h4>{Labels.Note}</h4>
            <Controller
              name="note"
              control={control}
              render={({ field }) => <Input type="textarea" {...field} />}
            />
          </FormGroup>
          <h4>{Labels.Url}</h4>
          <URLInput name="url" control={control} errors={errors} />
          <MultiSelect
            name="condition_groups"
            label={Labels.ConditionGroup}
            control={control}
            options={optionsGroup.conditionGroupOptions}
            isDisabled={isNormalUser || isShow}
          />
          <Checkbox id="status" label={Labels.Status} register={register} />

          {!isShow && <OnSubmitButton content={Labels.Save} />}
        </fieldset>
      </Form>
    </OriginCard>
  )
}

export default FormCard
