import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

type TOriginCard = {
  children: React.ReactNode
  title: string
  marginTop?: boolean
  col?: number
}

const OriginCard: React.FC<TOriginCard> = ({
  children,
  title,
  marginTop,
  col,
}: TOriginCard) => (
  <div className="main-content">
    {marginTop && <div className="header py-7 py-lg-8" />}
    <Container className={marginTop ? 'mt--8 pb-5' : 'mt-3'}>
      <Row className="justify-content-center">
        <Col lg={col ?? 12} md={col ?? 12}>
          <div className="pl-2 py-1 left-border-line">{title}</div>
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">{children}</CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
)

export default OriginCard
