import { Confirm as TConfirm } from 'common/types/confirm'
import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { modalService } from 'services/modal'

const Confirm: React.FC = () => {
  const [modal, setModal] = useState<TConfirm>({
    isShow: false,
    message: '',
    onOk: () => {},
  })

  useEffect(() => {
    const subscription = modalService
      .onModal()
      .subscribe((modalObj: TConfirm) => setModal(modalObj))
    return () => subscription.unsubscribe()
  }, [])

  const close = () => modalService.close(modal)
  const ok = () => {
    close()
    modal.onOk()
  }

  const modalSecondBtn = (
    <Button color="secondary" onClick={close}>
      キャンセル
    </Button>
  )

  return (
    <Modal isOpen={modal.isShow} toggle={close} centered onClosed={close}>
      <ModalBody>{modal.message}</ModalBody>
      <ModalFooter style={{ borderTop: 'unset' }}>
        {modalSecondBtn}
        <Button color="primary" onClick={ok}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Confirm
