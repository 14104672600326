import { Labels, Routes } from 'common/enums'
import StandardTable from 'components/atoms/Table/StandardTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Column } from 'react-table'
import { Permission } from 'common/types/permission'
import { getPermissions } from 'services/admin/permission'
import { PermissionTable } from 'common/types/table/permission'
import ButtonRight from 'components/atoms/Button/Right'
import OriginCard from 'components/atoms/Common/OriginCard'

const TableCard: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [permissions, setPermissions] = useState<Permission[]>([])

  useEffect(() => {
    getPermissions(storeCache, setPermissions)
  }, [storeCache])

  const columns: Column<PermissionTable>[] = useMemo(
    () => [
      { Header: Labels.ID, accessor: 'id', sortType: 'number' },
      { Header: Labels.PermissionName, accessor: 'name', sortType: 'basic' },
      {
        Header: Labels.PermissionPermissionSize,
        accessor: 'permission_size',
        sortType: 'basic',
      },
    ],
    []
  )
  const data: PermissionTable[] = useMemo(
    () =>
      permissions.map(({ id, name, permission_size }: Permission) => ({
        id,
        name,
        permission_size,
      })),
    [permissions]
  )

  return (
    <OriginCard title="権限管理">
      <ButtonRight
        history={history}
        nextPage={Routes.ManagementMenu}
        content={Labels.GoBackManagementMenu}
      />
      <StandardTable columns={columns} data={data} />
    </OriginCard>
  )
}

export default TableCard
