import { get } from 'common/fetch'
import { Pagination } from 'common/types/pagination'

const API_BASE_URL = '/logged_in/'

/**
 * ログイン履歴を初期ページ分取得する
 */
export const fetch = async (accesstoken: string): Promise<Pagination> =>
  await get<Pagination>(accesstoken, API_BASE_URL)

/**
 * ログイン履歴の前後どちらかのページを取得する
 */
export const fetchPage = async (
  accesstoken: string,
  url: string
): Promise<Pagination> =>
  await get<Pagination>(accesstoken, API_BASE_URL, { p: url.split('?p=')[1] })
