import { Confirm } from 'common/types/confirm'
import { Subject } from 'rxjs'

const modalSubject = new Subject<Confirm>()

export const modalService = {
  onModal,
  show,
  close,
}

function onModal() {
  return modalSubject.asObservable()
}

function show(message: string, onOk: () => void) {
  modalSubject.next({ isShow: true, message, onOk })
}

function close(closeModal: Confirm) {
  modalSubject.next({ ...closeModal, isShow: false })
}
