import React from 'react'
import { Container } from 'reactstrap'

const TermsOfUse: React.FC = () => (
  <Container className="text-center mt-5 mb--8">
    <h1>利用規約</h1>
  </Container>
)

export default TermsOfUse
