import {
  ages,
  CONDITION_LABELS,
  CONNECTIONS,
  HANDBOOK_TYPES,
  RECEIPTS,
  REGISTER_STATUS,
  SCHOOL_ATTENDANCES,
} from 'common/const'
import { getSearchLabel } from 'common/utils'
import React from 'react'

/**
 * selectのoptionを生成する
 */
export const selectOptions = (op: any[]): JSX.Element[] => {
  const options: JSX.Element[] = [<option value={undefined} />]
  op.forEach(({ id, name }) =>
    options.push(
      <option key={id} value={id}>
        {name}
      </option>
    )
  )
  return options
}

/**
 * 年齢の選択肢を生成する
 */
export const generateAges = (): JSX.Element[] =>
  selectOptions(ages().map((a) => ({ id: a, name: a })))

/**
 * 就学状況の選択肢を生成する
 */
export const generateSchoolAttendances = (): JSX.Element[] =>
  selectOptions(SCHOOL_ATTENDANCES.map((sa) => ({ id: sa, name: sa })))

/**
 * 就学状況の選択肢を生成する
 */
export const generateHandbookTypes = (): JSX.Element[] =>
  selectOptions(HANDBOOK_TYPES.map((ht) => ({ id: ht, name: ht })))

/**
 * 就学状況の選択肢を生成する
 */
export const generateReceipts = (): JSX.Element[] =>
  selectOptions(RECEIPTS.map((r) => ({ id: r, name: r })))

/**
 * 扶養人数の選択肢を生成する
 */
export const generateRegisterStatus = (): JSX.Element[] =>
  selectOptions(REGISTER_STATUS)

/**
 * 条件検索ラベルの選択肢を生成する
 */
export const generateConditionLabels = (
  isConditionGroup: boolean
): JSX.Element[] =>
  selectOptions(
    CONDITION_LABELS.map((cl) => ({ id: cl, name: getSearchLabel(cl) })).filter(
      ({ id }) =>
        isConditionGroup ||
        (!isConditionGroup &&
          !['condition_groups', 'condition_patterns'].includes(id))
    )
  )

/**
 * 条件式を組み合わせの選択肢を生成する
 */
export const generateConnections = (): JSX.Element[] =>
  selectOptions(CONNECTIONS.map((c) => ({ id: c, name: c })))
