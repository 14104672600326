import { SearchLabels } from 'common/enums'
import { IStoreCache } from 'common/types/auth_provider'
import { HomeForm } from 'common/types/form/home'
import { createSelectOptions } from 'common/utils'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import Select, { OptionsOrGroups } from 'react-select'
import { Col, FormGroup, Input, Row } from 'reactstrap'

type TFreeWord = {
  control: Control<HomeForm, object>
  storeCache: IStoreCache
}

const FreeWord: React.FC<TFreeWord> = ({ control, storeCache }: TFreeWord) => {
  const tagOptions: OptionsOrGroups<string, any> = createSelectOptions(
    storeCache.tags
  )

  return (
    <Row className="mb-3">
      <Col sm={6}>
        <h5>{SearchLabels.FreeWord}</h5>
        <FormGroup>
          <Controller
            name="free_word"
            control={control}
            render={({ field }) => <Input type="text" {...field} />}
          />
        </FormGroup>
      </Col>
      <Col sm={6}>
        <h5>{SearchLabels.Tags}</h5>
        <FormGroup>
          <Controller
            name="tags"
            control={control}
            render={({ field }) => (
              <Select
                closeMenuOnSelect={false}
                isMulti
                options={tagOptions}
                {...field}
              />
            )}
          />
        </FormGroup>
      </Col>
    </Row>
  )
}

export default FreeWord
