import { Labels, SearchLabels } from 'common/enums'

// 権限運営管理者の最大数値
export const PERMISSION_SEMI_ADMIN_MAX_NUM = 100
// 権限一般の最大数値
export const PERMISSION_NOMAL_MAX_NUM = 1000

// 権限一般の最大数値
const MAX_AGE = 30

/**
 * 年齢一覧
 */
export const ages = (): string[] => {
  const result: string[] = []
  for (let age = 0; age <= MAX_AGE; ++age) result.push(String(age))
  return result
}

// 就学状況一覧
export const SCHOOL_ATTENDANCES: string[] = [
  '未就学',
  '小学生',
  '中学生',
  '高校生',
  '大学生',
]

// 障がい者手帳の種類一覧
export const HANDBOOK_TYPES: string[] = [
  '1級',
  '概ね1級',
  '2級',
  '概ね2級',
  '3級',
  '概ね3級',
  '障害年金の障害等級1級',
  '障害年金の障害等級2級',
]

// 特別児童手当扶養受給一覧
export const RECEIPTS: string[] = ['受給あり', '受給なし']

// 登録ステータス一覧
export const REGISTER_STATUS: { id: number; name: string }[] = [
  { id: 0, name: '仮登録' },
  { id: 1, name: '本登録' },
]

// 検索ラベル
export const SEARCH_LABELS: any = {
  free_word: SearchLabels.FreeWord,
  condition_groups: Labels.ConditionGroup,
  condition_patterns: Labels.ConditionPatterns,
  tags: SearchLabels.Tags,
  parent_residence: SearchLabels.ParentResidence,
  child_residence: SearchLabels.ChildResidence,
  url: Labels.Url,
  area: Labels.Area,
  age: SearchLabels.Age,
  school_attendance: SearchLabels.SchoolAttendance,
  physical_disability_certificate_child:
    SearchLabels.PhysicalDisabilityCertificateChild,
  raising_child_handbook_child: SearchLabels.RaisingChildHandbookChild,
  mental_disability_certificate_child:
    SearchLabels.MentalDisabilityCertificateChild,
  sp_child_allowance_support_receipt:
    SearchLabels.SpChildAllowanceSupportReceipt,
  other_children_fault: SearchLabels.OtherChildrenFault,
  physical_disability_certificate_parent:
    SearchLabels.PhysicalDisabilityCertificateParent,
  raising_child_handbook_parent: SearchLabels.RaisingChildHandbookParent,
  mental_disability_certificate_parent:
    SearchLabels.MentalDisabilityCertificateParent,
  parents_which_severe_fault: SearchLabels.ParentsWhichSevereFault,
  other_parent_fault: SearchLabels.OtherParentFault,
  mother: SearchLabels.Mother,
  father: SearchLabels.Father,
  parents_except_raising_child: SearchLabels.ParentsExceptRaisingChild,
  family_except_raising_child: SearchLabels.FamilyExceptRaisingChild,
  support_count: SearchLabels.SupportCount,
  raising_child_person_taxation: SearchLabels.RaisingChildPersonTaxation,
  recipient_qualification_person_income:
    SearchLabels.RecipientQualificationPersonIncome,
  support_obligation_person_income: SearchLabels.SupportObligationPersonIncome,
  abnormal: SearchLabels.Abnormal,
  livelihood_subsidies: SearchLabels.LivelihoodSubsidies,
  supportCount: Labels.SupportCount,
  incomeMax: Labels.IncomeMax,
  is_single_parent: Labels.IsSingleParent,
  is_parents_except_raising_child: Labels.IsParentsExceptRaisingChild,
  is_family_except_raising_child: Labels.IsFamilyExceptRaisingChild,
  is_abnormal: SearchLabels.Abnormal,
  is_livelihood_subsidies: SearchLabels.LivelihoodSubsidies,
  is_raising_child_person_taxation: SearchLabels.RaisingChildPersonTaxation,
  keyword: Labels.Keywords,
}

// 条件検索ラベル一覧
export const CONDITION_LABELS: string[] = [
  'condition_groups',
  'condition_patterns',
  'tags',
  'url',
  'parent_residence',
  'child_residence',
  'age',
  'school_attendance',
  'physical_disability_certificate_child',
  'physical_disability_certificate_parent',
  'raising_child_handbook_child',
  'raising_child_handbook_parent',
  'mental_disability_certificate_child',
  'mental_disability_certificate_parent',
  'sp_child_allowance_support_receipt',
  'parents_which_severe_fault',
  'other_children_fault',
  'other_parent_fault',
  'is_single_parent',
  'is_parents_except_raising_child',
  'is_family_except_raising_child',
  'is_abnormal',
  'is_livelihood_subsidies',
  'is_raising_child_person_taxation',
  'support_count',
  'recipient_qualification_person_income',
  'support_obligation_person_income',
  'keyword',
]

// 条件式組み合わせ一覧
export const CONNECTIONS: string[] = ['AND', 'OR']
