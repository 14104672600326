import { Routes } from 'common/enums'
import { Member } from 'common/types/member'
import FormCard from 'components/organisms/Admin/MemberForm/FormCard'
import React from 'react'
import { useLocation } from 'react-router'

const MemberForm: React.FC = () => {
  const { pathname, state } = useLocation()

  const isCreate = pathname === Routes.MemberCreate
  const member = state as Member

  return <FormCard isCreate={isCreate} member={member} />
}

export default MemberForm
