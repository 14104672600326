import { Routes } from 'common/enums'
import { SupportSystem } from 'common/types/support_system'
import FormCard from 'components/organisms/User/SupportSystemForm/FormCard'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router'
import { isNormalUserOrLess } from 'services/auth'

const SupportSystemForm: React.FC = () => {
  const { user } = useContext(AuthContext).storeCache
  const { replace } = useHistory()
  const { pathname, state } = useLocation()

  const isShow = pathname === Routes.SupportSystemShow

  if (isNormalUserOrLess(user) && !isShow) replace(Routes.Home)

  return (
    <FormCard
      isCreate={pathname === Routes.SupportSystemCreate}
      isShow={isShow}
      supportSystem={state as SupportSystem}
    />
  )
}

export default SupportSystemForm
