import { ConditionItem } from 'common/types/form/condition_pattern'
import { generateYesNoStr } from 'common/utils'
import URLInput from 'components/atoms/Form/Input/URLInput'
import YesNo from 'components/atoms/Form/Radio/YesNo'
import CreateSelect from 'components/atoms/Form/Select/CreateSelect'
import MultiSelect from 'components/atoms/Form/Select/MultiSelect'
import SingleSelect from 'components/atoms/Form/Select/SingleSelect'
import React from 'react'
import { Controller } from 'react-hook-form'
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import { OptionsGroup } from 'services/admin/condition'

type TConditionInput = {
  name: string
  optionsGroup: OptionsGroup
  control: any
  register: any
  conditionItem: ConditionItem
}

const ConditionInput: React.FC<TConditionInput> = ({
  name,
  optionsGroup,
  control,
  register,
  conditionItem: { label, content },
}: TConditionInput) => {
  switch (label) {
    case 'condition_groups':
    case 'condition_patterns':
      return (
        <SingleSelect
          name={name}
          control={control}
          options={
            label === 'condition_groups'
              ? optionsGroup.conditionGroupOptions
              : optionsGroup.conditionPatternOptions
          }
        />
      )
    case 'tags':
      return (
        <MultiSelect
          name={name}
          control={control}
          options={optionsGroup.tagOptions}
        />
      )
    case 'url':
      return <URLInput name={name} control={control} />
    case 'parent_residence':
    case 'child_residence':
      return (
        <MultiSelect
          name={name}
          control={control}
          options={optionsGroup.areaOptions}
        />
      )
    case 'age':
      return (
        <MultiSelect
          name={name}
          control={control}
          options={optionsGroup.ageOptions}
        />
      )
    case 'school_attendance':
      return (
        <MultiSelect
          name={name}
          control={control}
          options={optionsGroup.schoolAttendanceOptions}
        />
      )
    case 'physical_disability_certificate_child':
    case 'physical_disability_certificate_parent':
    case 'raising_child_handbook_child':
    case 'raising_child_handbook_parent':
    case 'mental_disability_certificate_child':
    case 'mental_disability_certificate_parent':
      return (
        <MultiSelect
          name={name}
          control={control}
          options={optionsGroup.handbookTypeOptions}
        />
      )
    case 'sp_child_allowance_support_receipt':
      return (
        <MultiSelect
          name={name}
          control={control}
          options={optionsGroup.receiptOptions}
        />
      )
    case 'parents_which_severe_fault':
    case 'other_children_fault':
    case 'other_parent_fault':
    case 'is_single_parent':
    case 'is_parents_except_raising_child':
    case 'is_family_except_raising_child':
    case 'is_abnormal':
    case 'is_livelihood_subsidies':
    case 'is_raising_child_person_taxation':
      return (
        <YesNo
          name={name}
          isFalse={content === false}
          trueMessage={generateYesNoStr(label, true)}
          falseMessage={generateYesNoStr(label, false)}
          marginBottom={4}
          register={register}
        />
      )
    case 'support_count':
    case 'recipient_qualification_person_income':
    case 'support_obligation_person_income':
      return (
        <FormGroup className="d-flex align-items-center my-4">
          <InputGroup>
            <Controller
              name={name}
              control={control}
              render={({ field }) => <Input type="number" {...field} />}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>{appendAddon(label)}</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      )
    case 'keyword':
      return <CreateSelect name={name} control={control} />
    default:
      return <></>
  }
}

const appendAddon = (label: string) => {
  switch (label) {
    case 'support_count':
      return '人'
    case 'recipient_qualification_person_income':
    case 'support_obligation_person_income':
      return '万円'
    default:
      return ''
  }
}

export default ConditionInput
