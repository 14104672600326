import FormBody from 'components/organisms/Login/FormBody'
import React from 'react'
import { Container } from 'reactstrap'

const Login: React.FC = () => (
  <>
    <Container className="text-center mt-5 mb--5">
      <h1>使える制度がわかるシステム</h1>
    </Container>
    <FormBody />
    <footer className="text-center mt-5 mb--8">
      <div>
        <a href="https://kyuminyokin.info/" target="_blank" rel="noreferrer">
          <img
            src="https://www.janpia.or.jp/dantai/symbol/download/banner/symbol_banner_160x55.jpg"
            width="160"
            height="55"
            alt="舞い上がれ 社会を変える みんなの力 休眠預金活用事業"
          />
        </a>
      </div>
      {/* <div>
        <Link to={Routes.TermsOfUse}>利用規約</Link>
      </div> */}
      <div className="mt-2">
        運営：
        <a href="http://www.npokayama.org/" target="_blank" rel="noreferrer">
          NPO法人岡山NPOセンター
        </a>
        <a href="https://kotomofund.jp/" target="_blank" rel="noreferrer">
          （ KOTOMO基金 ）
        </a>
      </div>
    </footer>
  </>
)

export default Login
