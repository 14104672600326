import { ButtonColor, Labels, Routes } from 'common/enums'
import {
  filterConditionGroups,
  filterSupportSystems,
} from 'common/find_store_cache'
import { SupportOrganization } from 'common/types/support_organization'
import { SupportOrganizationTable } from 'common/types/table/support_organization'
import { convertConditionTitle } from 'common/utils'
import { NomalBadges } from 'components/atoms/Badge/NomalBadges'
import ButtonRight from 'components/atoms/Button/Right'
import SmallButton from 'components/atoms/Button/SmallButton'
import OriginCard from 'components/atoms/Common/OriginCard'
import StandardTable from 'components/atoms/Table/StandardTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'
import { isNormalUserOrLess } from 'services/auth'
import { deleteSupportOrganization } from 'services/user/support_organization'

const TableCard: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const isNotNormalUser = !isNormalUserOrLess(storeCache.user)

  const columns: Column<SupportOrganizationTable>[] = useMemo(
    () => [
      { Header: Labels.ID, accessor: 'id', sortType: 'basic' },
      { Header: Labels.SupportOrganizationName, accessor: 'name' },
      { Header: Labels.BusinessDetail, accessor: 'business_detail' },
      { Header: Labels.Url, accessor: 'url' },
      { Header: Labels.Email, accessor: 'email' },
      { Header: Labels.Tel, accessor: 'tel' },
      { Header: Labels.Address, accessor: 'address' },
      { Header: Labels.SupportSystemName, accessor: 'support_systems' },
      { Header: Labels.ConditionGroup, accessor: 'condition_groups' },
      { Header: Labels.Status, accessor: 'status' },
      { Header: Labels.Note, accessor: 'note' },
      { Header: Labels.Edit, accessor: 'edit', disableSortBy: true },
    ],
    []
  )
  const data: SupportOrganizationTable[] = useMemo(
    () =>
      storeCache.supportOrganizations.map((so: SupportOrganization) => ({
        id: so.id,
        name: (
          <Link
            to={{
              pathname: isNotNormalUser
                ? Routes.SupportOrganizationEdit
                : Routes.SupportOrganizationShow,
              state: so,
            }}
          >
            {so.name}
          </Link>
        ),
        business_detail: so.business_detail,
        url: so.url,
        email: so.email,
        tel: so.tel,
        address: so.address,
        support_systems: NomalBadges(
          filterSupportSystems(storeCache, so.support_systems).map(
            ({ name }) => name
          ),
          'info'
        ),
        condition_groups: NomalBadges(
          filterConditionGroups(storeCache, so.condition_groups ?? []).map(
            ({ id, name }) => name ?? `${convertConditionTitle(true)}${id}`
          ),
          'primary'
        ),
        status: so.status ? '表示' : '非表示',
        note: so.note,
        edit: isNotNormalUser ? (
          <>
            <SmallButton
              color={ButtonColor.INFO}
              content={Labels.Edit}
              onClick={() => history.push(Routes.SupportOrganizationEdit, so)}
            />
            <SmallButton
              color={ButtonColor.DANGER}
              content={Labels.Delete}
              onClick={() => deleteSupportOrganization(storeCache, so)}
            />
          </>
        ) : (
          <SmallButton
            color={ButtonColor.SECONDARY}
            content={Labels.Description}
            onClick={() => history.push(Routes.SupportOrganizationShow, so)}
          />
        ),
      })),
    [storeCache, history, isNotNormalUser]
  )

  return (
    <OriginCard title={Labels.SupportOrganizationName}>
      {isNotNormalUser && (
        <ButtonRight
          history={history}
          nextPage={Routes.SupportOrganizationCreate}
          content={Labels.Create}
        />
      )}
      <StandardTable columns={columns} data={data} />
    </OriginCard>
  )
}

export default TableCard
