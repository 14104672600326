import { IStoreCache } from 'common/types/auth_provider'
import { Pagination } from 'common/types/pagination'
import { getErrorMessage } from 'common/utils'
import { fetch, fetchPage } from 'repositories/logged_in'

/**
 * ログイン履歴を初期ページ分取得する
 */
export const getLoggedIns = async (
  { accesstoken }: IStoreCache,
  setData: React.Dispatch<React.SetStateAction<Pagination | null>>
): Promise<void> => {
  try {
    setData(await fetch(accesstoken))
  } catch (error) {
    getErrorMessage(error)
  }
}

/**
 * ログイン履歴の前後どちらかのページを取得する
 */
export const getLogsPage = async (
  { accesstoken }: IStoreCache,
  url: string | null,
  setData: React.Dispatch<React.SetStateAction<Pagination | null>>,
  isPrevious: boolean = false
): Promise<void> => {
  try {
    if (!url) throw new Error(`${isPrevious ? '先頭' : '最後'}のページです`)

    setData(await fetchPage(accesstoken, url))
  } catch (error) {
    getErrorMessage(error)
  }
}
