import React from 'react'
import 'styles/fullloadingspinner.scss'

const FullLoadingSpinner: React.FC = () => (
  <div className="loading-spinner-container">
    <div className="loading-spinner" />
  </div>
)

export default FullLoadingSpinner
