import { Routes } from 'common/enums'
import { Area } from 'common/types/area'
import { IStoreCache } from 'common/types/auth_provider'
import { AreaForm } from 'common/types/form/area'
import { getErrorMessage } from 'common/utils'
import { History } from 'history'
import { reloadCachedAreas } from 'providers/AuthProvider'
import { create, remove, update } from 'repositories/area'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * 地域を作成 or 更新する
 */
export const saveArea = async (
  storeCache: IStoreCache,
  { push }: History,
  isCreate: boolean,
  data: AreaForm,
  id: number | null
): Promise<void> => {
  try {
    if (
      storeCache.areas.some(
        ({ id: pk, name }) => pk !== id && name === data.name
      )
    )
      throw new Error('既に同じ名前の地域が登録されてます。')

    const { name }: Area = isCreate
      ? await create(storeCache.accesstoken, data)
      : await update(storeCache.accesstoken, data, id!)
    await reloadCachedAreas(storeCache)

    alertService.show(true, `${name}の保存が完了しました。`)
    push(Routes.Area)
  } catch (error) {
    getErrorMessage(error)
  }
}

/**
 * 地域を削除する
 */
export const deleteArea = (
  storeCache: IStoreCache,
  { name, id }: Area
): void => {
  try {
    modalService.show(`${name}を削除しますか？`, async () => {
      await remove(storeCache.accesstoken, id, storeCache.user!.id)
      await reloadCachedAreas(storeCache)
      alertService.show(true, `${name}の削除が完了しました。`)
    })
  } catch (error) {
    getErrorMessage(error)
  }
}
