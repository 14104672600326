import { Labels, Routes } from 'common/enums'
import { MemberForm } from 'common/types/form/member'
import { Member } from 'common/types/member'
import { Permission } from 'common/types/permission'
import {
  createMaxLengthMessage,
  createRegExpMessage,
  createRequiredMessage,
  getTitle,
} from 'common/utils'
import OnSubmitButton from 'components/atoms/Button/OnSubmitButton'
import ButtonRight from 'components/atoms/Button/Right'
import OriginCard from 'components/atoms/Common/OriginCard'
import Feedback from 'components/atoms/Feedback/Feedback'
import { selectOptions } from 'components/atoms/Select/Options'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Form, FormGroup, Input } from 'reactstrap'
import { saveMember } from 'services/admin/member'
import { getPermissions } from 'services/admin/permission'

type TFormCard = {
  isCreate: boolean
  member: Member
}

const FormCard: React.FC<TFormCard> = ({ isCreate, member }: TFormCard) => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const [permissions, setPermissions] = useState<Permission[]>([])

  useEffect(() => {
    getPermissions(storeCache, setPermissions)
  }, [storeCache])

  const defaultValues = isCreate
    ? {
        username: '',
        email: '',
        password: '',
        password_confirm: '',
        area: 0,
        organization: 0,
        permission: 0,
        member_id: storeCache.user!.id,
      }
    : {
        username: member.username,
        email: member.email,
        password: '',
        password_confirm: '',
        area: member.area,
        organization: member.organization,
        permission: member.permission,
        member_id: storeCache.user!.id,
      }
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<MemberForm>({ defaultValues })

  const save = (data: MemberForm) =>
    saveMember(storeCache, history, isCreate, data, isCreate ? null : member.id)

  return (
    <OriginCard title={`メンバー${getTitle(isCreate, false)}`}>
      <ButtonRight
        history={history}
        nextPage={Routes.Member}
        content={Labels.GoBack}
      />
      <Form role="form" onSubmit={handleSubmit(save)}>
        <h4>{Labels.MemberUsername}</h4>
        <FormGroup className="mb-5">
          <Controller
            name="username"
            control={control}
            rules={{
              required: createRequiredMessage(Labels.MemberUsername),
              maxLength: {
                value: 150,
                message: createMaxLengthMessage(Labels.MemberUsername, 150),
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                className={errors.username && 'is-invalid'}
                {...field}
              />
            )}
          />
          <Feedback error={errors.username} />
        </FormGroup>
        <h4>{Labels.Email}</h4>
        <FormGroup className="mb-5">
          <Controller
            name="email"
            control={control}
            rules={{
              required: createRequiredMessage(Labels.Email),
              maxLength: {
                value: 254,
                message: createMaxLengthMessage(Labels.Email, 254),
              },
              pattern: {
                value: /^\S+@\S+$/i,
                message: createRegExpMessage(Labels.Email),
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                className={errors.email && 'is-invalid'}
                {...field}
              />
            )}
          />
          <Feedback error={errors.email} />
        </FormGroup>
        <h4>{Labels.Password}</h4>
        <FormGroup className="mb-5">
          <Controller
            name="password"
            control={control}
            rules={{
              required: createRequiredMessage(Labels.Password),
              maxLength: {
                value: 128,
                message: createMaxLengthMessage(Labels.Password, 128),
              },
            }}
            render={({ field }) => (
              <Input
                type="password"
                className={errors.password && 'is-invalid'}
                {...field}
              />
            )}
          />
          <Feedback error={errors.password} />
        </FormGroup>
        <h4>{Labels.PasswordConfirm}</h4>
        <FormGroup className="mb-5">
          <Controller
            name="password_confirm"
            control={control}
            rules={{
              required: createRequiredMessage(Labels.PasswordConfirm),
              maxLength: {
                value: 128,
                message: createMaxLengthMessage(Labels.PasswordConfirm, 128),
              },
            }}
            render={({ field }) => (
              <Input
                type="password"
                className={errors.password_confirm && 'is-invalid'}
                {...field}
              />
            )}
          />
          <Feedback error={errors.password_confirm} />
        </FormGroup>
        <h4>{Labels.Area}</h4>
        <FormGroup className="mb-5">
          <Controller
            name="area"
            control={control}
            render={({ field }) => (
              <Input
                type="select"
                className={errors.area && 'is-invalid'}
                {...field}
              >
                {selectOptions(storeCache.areas)}
              </Input>
            )}
          />
          <Feedback error={errors.area} />
        </FormGroup>
        <h4>{Labels.Organization}</h4>
        <FormGroup className="mb-5">
          <Controller
            name="organization"
            control={control}
            render={({ field }) => (
              <Input
                type="select"
                className={errors.organization && 'is-invalid'}
                {...field}
              >
                {selectOptions(storeCache.organizations)}
              </Input>
            )}
          />
          <Feedback error={errors.organization} />
        </FormGroup>
        <h4>{Labels.Permission}</h4>
        <FormGroup className="mb-5">
          <Controller
            name="permission"
            control={control}
            rules={{ required: createRequiredMessage(Labels.Permission) }}
            render={({ field }) => (
              <Input
                type="select"
                className={errors.permission && 'is-invalid'}
                {...field}
              >
                {selectOptions(permissions)}
              </Input>
            )}
          />
          <Feedback error={errors.permission} />
        </FormGroup>
        <OnSubmitButton content={Labels.Save} />
      </Form>
    </OriginCard>
  )
}

export default FormCard
