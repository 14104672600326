import { ButtonColor, Labels, Routes } from 'common/enums'
import {
  filterSupportOrganizations,
  filterSupportSystems,
  filterTags,
} from 'common/find_store_cache'
import { Case } from 'common/types/case'
import { CaseForm } from 'common/types/form/case'
import { HomeForm } from 'common/types/form/home'
import {
  createMaxLengthMessage,
  createRequiredMessage,
  createSelectOptions,
  getSearchLabel,
  getTitle,
} from 'common/utils'
import { BadgesForObject } from 'components/atoms/Badge/NomalBadges'
import OnSubmitButton from 'components/atoms/Button/OnSubmitButton'
import SmallButton from 'components/atoms/Button/SmallButton'
import OriginCard from 'components/atoms/Common/OriginCard'
import Feedback from 'components/atoms/Feedback/Feedback'
import { generateRegisterStatus } from 'components/atoms/Select/Options'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import Select, { OptionsOrGroups } from 'react-select'
import { Form, FormGroup, Input, Row } from 'reactstrap'
import { saveCase } from 'services/user/case'

type TFormCard = {
  isCreate: boolean
  isShow: boolean
  caseObj: Case
}

const FormCard: React.FC<TFormCard> = ({
  isCreate,
  isShow,
  caseObj,
}: TFormCard) => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const defaultValues = isCreate
    ? {
        name: '',
        description: '',
        tags: [],
        support_organizations: [],
        support_systems: [],
        search_criteria: {} as HomeForm,
        register_status: 0,
        member_id: storeCache.user!.id,
      }
    : {
        name: caseObj.name ?? '',
        description: caseObj.description ?? '',
        tags: createSelectOptions(
          filterTags(storeCache, caseObj.tags)
        ) as any[],
        support_organizations: createSelectOptions(
          filterSupportOrganizations(storeCache, caseObj.support_organizations)
        ) as any[],
        support_systems: createSelectOptions(
          filterSupportSystems(storeCache, caseObj.support_systems)
        ) as any[],
        search_criteria: caseObj.search_criteria as HomeForm,
        register_status: caseObj.register_status,
        member_id: storeCache.user!.id,
      }
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CaseForm>({ defaultValues })

  const tagOptions: OptionsOrGroups<string, any> = createSelectOptions(
    storeCache.tags
  )
  const supportOrganizationOptions: OptionsOrGroups<string, any> =
    createSelectOptions(storeCache.supportOrganizations)
  const supportSystemOptions: OptionsOrGroups<string, any> =
    createSelectOptions(storeCache.supportSystems)

  const save = (data: CaseForm) =>
    saveCase(storeCache, history, isCreate, data, isCreate ? null : caseObj.id)

  return (
    <OriginCard title={`事例${getTitle(false, isShow)}`}>
      <Row className="justify-content-end">
        <SmallButton
          color={ButtonColor.DEFAULT}
          content={Labels.GoBack}
          onClick={() => history.goBack()}
        />
        {isShow && (
          <SmallButton
            color={ButtonColor.SECONDARY}
            content={Labels.Edit}
            onClick={() => history.push(Routes.CaseEdit, caseObj)}
          />
        )}
      </Row>
      <Form role="form" onSubmit={handleSubmit(save)}>
        <FormGroup className="mb-5">
          <h4>{Labels.CaseName}</h4>
          <Controller
            name="name"
            control={control}
            rules={{
              required: createRequiredMessage(Labels.CaseName),
              maxLength: {
                value: 255,
                message: createMaxLengthMessage(Labels.CaseName, 255),
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                list="name"
                disabled={isShow}
                className={errors.name && 'is-invalid'}
                {...field}
              />
            )}
          />
          <datalist id="name">
            {storeCache.cases.map(({ name }, i) => (
              <option key={i} value={name} />
            ))}
          </datalist>
          <Feedback error={errors.name} />
        </FormGroup>
        <FormGroup className="mb-5">
          <h4>{Labels.Description}</h4>
          <Controller
            name="description"
            control={control}
            rules={{
              required: createRequiredMessage(Labels.Description),
              maxLength: {
                value: 5000,
                message: createMaxLengthMessage(Labels.Description, 5000),
              },
            }}
            render={({ field }) => (
              <Input
                type="textarea"
                disabled={isShow}
                className={errors.name && 'is-invalid'}
                {...field}
              />
            )}
          />
          <Feedback error={errors.description} />
        </FormGroup>
        <FormGroup className="mb-5">
          <h4>{Labels.Tag}</h4>
          <Controller
            name="tags"
            control={control}
            render={({ field }) => (
              <Select
                closeMenuOnSelect={false}
                isMulti
                isDisabled={isShow}
                options={tagOptions}
                {...field}
              />
            )}
          />
        </FormGroup>
        <FormGroup className="mb-5">
          <h4>{Labels.SupportOrganizationName}</h4>
          <Controller
            name="support_organizations"
            control={control}
            render={({ field }) => (
              <Select
                closeMenuOnSelect={false}
                isMulti
                isDisabled={isShow}
                options={supportOrganizationOptions}
                {...field}
              />
            )}
          />
        </FormGroup>
        <FormGroup className="mb-5">
          <h4>{Labels.SupportSystemName}</h4>
          <Controller
            name="support_systems"
            control={control}
            render={({ field }) => (
              <Select
                closeMenuOnSelect={false}
                isMulti
                isDisabled={isShow}
                options={supportSystemOptions}
                {...field}
              />
            )}
          />
        </FormGroup>
        <h4>{Labels.RegisterStatus}</h4>
        <FormGroup className="mb-5">
          <Controller
            name="register_status"
            control={control}
            render={({ field }) => (
              <Input
                type="select"
                disabled={isShow}
                className={errors.register_status && 'is-invalid'}
                {...field}
              >
                {generateRegisterStatus()}
              </Input>
            )}
          />
          <Feedback error={errors.register_status} />
        </FormGroup>
        <h4>{Labels.SearchCriteria}</h4>
        {!isCreate &&
          BadgesForObject(
            caseObj.search_criteria,
            (key) => getSearchLabel(key),
            'warning',
            'success'
          )}
        {!isShow && <OnSubmitButton content={Labels.Save} />}
        {Object.keys(errors).map((key) => {
          const error = (errors as any)[key] as any
          if (!error) return <></>

          return <div style={{ color: 'red' }}>※{error.message}</div>
        })}
      </Form>
    </OriginCard>
  )
}

export default FormCard
