import { Labels, Routes } from 'common/enums'
import { LoginForm } from 'common/types/form/login'
import { createRequiredMessage } from 'common/utils'
import OnSubmitButton from 'components/atoms/Button/OnSubmitButton'
import OriginCard from 'components/atoms/Common/OriginCard'
import Feedback from 'components/atoms/Feedback/Feedback'
import { useAuthContext } from 'providers/AuthProvider'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Col, Form, FormGroup, Input, Label } from 'reactstrap'
import { loginUser } from 'services/login'

const FormBody: React.FC = () => {
  const { storeCache } = useAuthContext()
  const { replace } = useHistory()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({ defaultValues: { id: '', password: '' } })

  const login = (data: LoginForm): Promise<void> => loginUser(data, storeCache)

  if (storeCache.accesstoken) replace(Routes.Home)

  return (
    <OriginCard title="ログイン" marginTop col={8}>
      <Form role="form" onSubmit={handleSubmit(login)}>
        <FormGroup className="mb-3" row>
          <Label sm={4}>{Labels.ID}</Label>
          <Col sm={8}>
            <Controller
              name="id"
              control={control}
              rules={{ required: createRequiredMessage(Labels.ID) }}
              render={({ field }) => (
                <Input
                  type="text"
                  className={errors.id && 'is-invalid'}
                  {...field}
                />
              )}
            />
          </Col>
          <Feedback error={errors.id} />
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>{Labels.Password}</Label>
          <Col sm={8}>
            <Controller
              name="password"
              control={control}
              rules={{ required: createRequiredMessage(Labels.Password) }}
              render={({ field }) => (
                <Input
                  type="password"
                  className={errors.password && 'is-invalid'}
                  {...field}
                />
              )}
            />
          </Col>
          <Feedback error={errors.password} />
        </FormGroup>
        <div className="my-3">
          <ul>
            <li>
              本システムは、岡山県内における困難を抱える子ども・親子への支援が円滑に行われるための
              情報検索を目的としています。目的外のご利用はお控えください。
            </li>
            <li>
              本システムを利用される際、支援先となる個人や家庭の個人が特定される情報の入力は不要です。
            </li>
            <li>
              検索履歴は、今後の検索精度向上のために保存ならびに事務局により管理をさせていただきます。
            </li>
          </ul>
        </div>
        <OnSubmitButton content={Labels.Login} />
      </Form>
    </OriginCard>
  )
}

export default FormBody
