import { get, post } from 'common/fetch'
import { SystemMessage } from 'common/types/system_message'
import { fetch as fetchCsrf } from 'repositories/csrf'

const API_BASE_URL = '/system_message/'

/**
 * システム文言管理を取得する
 */
export const fetch = async (accesstoken: string): Promise<SystemMessage> =>
  await get<SystemMessage>(accesstoken, API_BASE_URL)

/**
 * システム文言管理を更新する
 */
export const update = async (
  accesstoken: string,
  data: SystemMessage
): Promise<void> =>
  await post<void>(
    accesstoken,
    `${API_BASE_URL}update`,
    data,
    await fetchCsrf()
  )
