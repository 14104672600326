import { Labels } from 'common/enums'
import { Condition } from 'common/types/condition'
import {
  ConditionArray,
  ConditionItem,
} from 'common/types/form/condition_pattern'
import { convertConditionTitle, getTitle } from 'common/utils'
import OnSubmitButton from 'components/atoms/Button/OnSubmitButton'
import ButtonRight from 'components/atoms/Button/Right'
import OriginCard from 'components/atoms/Common/OriginCard'
import {
  generateConditionLabels,
  generateConnections,
} from 'components/atoms/Select/Options'
import ConditionInput from 'components/molecules/Admin/ConditionPatternForm/ConditionInput'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Controller } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Button, Col, Form, FormGroup, Input } from 'reactstrap'
import {
  getOptionsGroup,
  initConditionItem,
  saveCondition,
  useInitConditionForm,
} from 'services/admin/condition'

type TFormCard = {
  isCreate: boolean
  isConditionGroup: boolean
  condition: Condition | undefined
}

const FormCard: React.FC<TFormCard> = ({
  isCreate,
  isConditionGroup,
  condition,
}: TFormCard) => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const { handleSubmit, register, append, remove, control, controlledFields } =
    useInitConditionForm(storeCache, condition)

  const optionsGroup = getOptionsGroup(storeCache, condition)

  const CONDITION_TITLE = convertConditionTitle(isConditionGroup)

  const save = (data: ConditionArray) =>
    saveCondition(
      storeCache,
      history,
      isCreate,
      isConditionGroup,
      data,
      condition?.id ?? null
    )

  return (
    <OriginCard title={`${CONDITION_TITLE}${getTitle(isCreate, false)}`}>
      <ButtonRight history={history} content={Labels.GoBack} />
      <Form role="form" onSubmit={handleSubmit(save)}>
        <FormGroup className="d-flex align-items-center mb-5" row>
          <h4>{`${CONDITION_TITLE}名`}</h4>
          <Col sm={3}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => <Input type="text" {...field} />}
            />
          </Col>
        </FormGroup>
        {controlledFields.map(
          (controlledField: ConditionItem, index: number) => (
            <div key={controlledField.id}>
              <FormGroup className="d-flex align-items-center my-4" row>
                <Col sm={10}>
                  <Controller
                    name={`items.${index}.label`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        type="select"
                        disabled={!!controlledField.label}
                        {...field}
                      >
                        {generateConditionLabels(isConditionGroup)}
                      </Input>
                    )}
                  />
                </Col>
                <Col sm={2}>
                  <Button color="primary" outline onClick={() => remove(index)}>
                    -
                  </Button>
                </Col>
              </FormGroup>
              <ConditionInput
                name={`items.${index}.content`}
                optionsGroup={optionsGroup}
                control={control}
                register={register}
                conditionItem={controlledField}
              />
              <FormGroup className="d-flex align-items-center mb-5" row>
                <Col sm={3}>
                  <Controller
                    name={`items.${index}.connection`}
                    control={control}
                    render={({ field }) => (
                      <Input type="select" {...field}>
                        {generateConnections()}
                      </Input>
                    )}
                  />
                </Col>
              </FormGroup>
              <hr />
            </div>
          )
        )}
        <div className="text-center my-2">
          <Button
            color="primary"
            outline
            onClick={() => append(initConditionItem())}
          >
            +
          </Button>
        </div>
        <OnSubmitButton content={Labels.Save} />
      </Form>
    </OriginCard>
  )
}

export default FormCard
