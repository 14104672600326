import { Labels, SearchLabels } from 'common/enums'
import { HomeForm } from 'common/types/form/home'
import CancelOnlyModal from 'components/atoms/Modal/CancelOnlyModal'
import { generateHandbookTypes } from 'components/atoms/Select/Options'
import React, { useState } from 'react'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'

type TParentDisorderDegree = {
  control: Control<HomeForm, object>
  register: UseFormRegister<HomeForm>
  otherParentFaultHtml: string
}

const ParentDisorderDegree: React.FC<TParentDisorderDegree> = ({
  control,
  register,
  otherParentFaultHtml,
}: TParentDisorderDegree) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Row className="mb-3">
      <Col sm="12">
        <h4>
          <i className="fas fa-square-full" />
          {SearchLabels.ParentDisorderDegree}
        </h4>
        <FormGroup className="d-flex align-items-center my-3" row>
          <Col sm={12}>
            <div className="custom-control custom-checkbox">
              <input
                {...register('parents_which_severe_fault')}
                className="custom-control-input"
                id="parents_which_severe_fault"
                type="checkbox"
              />
              <label
                className="custom-control-label"
                htmlFor="parents_which_severe_fault"
              >
                {SearchLabels.ParentsWhichSevereFault}
              </label>
            </div>
          </Col>
        </FormGroup>
        <FormGroup className="d-flex align-items-center my-4" row>
          <Col sm={2}>
            <Label>{Labels.PhysicalisabilityCertificate}</Label>
            <Controller
              name="physical_disability_certificate_parent"
              control={control}
              render={({ field }) => (
                <Input type="select" {...field}>
                  {generateHandbookTypes()}
                </Input>
              )}
            />
          </Col>
          <Col sm={2}>
            <Label>{Labels.RaisingChildHandbook}</Label>
            <Controller
              name="raising_child_handbook_parent"
              control={control}
              render={({ field }) => (
                <Input type="select" {...field}>
                  {generateHandbookTypes()}
                </Input>
              )}
            />
          </Col>
          <Col sm={3}>
            <Label>{Labels.MentalDisabilityCertificate}</Label>
            <Controller
              name="mental_disability_certificate_parent"
              control={control}
              render={({ field }) => (
                <Input type="select" {...field}>
                  {generateHandbookTypes()}
                </Input>
              )}
            />
          </Col>
          <Col sm={2}>
            <div className="custom-control custom-checkbox">
              <input
                {...register('other_parent_fault')}
                className="custom-control-input"
                id="other_parent_fault"
                type="checkbox"
              />
              <label
                className="custom-control-label"
                htmlFor="other_parent_fault"
              >
                {Labels.Other}
              </label>
              <CancelOnlyModal
                content="?"
                modalElement={otherParentFaultHtml}
                marginX="1"
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(!isOpen)}
              />
            </div>
          </Col>
        </FormGroup>
      </Col>
    </Row>
  )
}

export default ParentDisorderDegree
