import { Routes } from 'common/enums'
import { Case } from 'common/types/case'
import FormCard from 'components/organisms/User/CaseForm/FormCard'
import React from 'react'
import { useLocation } from 'react-router'

const CaseForm: React.FC = () => {
  const { pathname, state } = useLocation()

  return (
    <FormCard
      isCreate={pathname === Routes.CaseCreate}
      isShow={pathname === Routes.CaseShow}
      caseObj={state as Case}
    />
  )
}

export default CaseForm
