import { SearchLabels } from 'common/enums'
import { HomeForm } from 'common/types/form/home'
import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { Col, FormGroup, Row } from 'reactstrap'

type TFamilyStructure = {
  register: UseFormRegister<HomeForm>
}

const FamilyStructure: React.FC<TFamilyStructure> = ({
  register,
}: TFamilyStructure) => (
  <Row className="mb-4">
    <Col sm="12">
      <h4>
        <i className="fas fa-square-full" />
        {SearchLabels.FamilyStructure}
      </h4>
      <div className="my-4">
        <FormGroup check inline className="mr-3">
          <div className="custom-control custom-checkbox">
            <input
              {...register('mother')}
              className="custom-control-input"
              id="mother"
              type="checkbox"
            />
            <label className="custom-control-label" htmlFor="mother">
              {SearchLabels.Mother}
            </label>
          </div>
        </FormGroup>
        <FormGroup check inline className="mr-3">
          <div className="custom-control custom-checkbox">
            <input
              {...register('father')}
              className="custom-control-input"
              id="father"
              type="checkbox"
            />
            <label className="custom-control-label" htmlFor="father">
              {SearchLabels.Father}
            </label>
          </div>
        </FormGroup>
        <FormGroup check inline className="mr-3">
          <div className="custom-control custom-checkbox">
            <input
              {...register('parents_except_raising_child')}
              className="custom-control-input"
              id="parents_except_raising_child"
              type="checkbox"
            />
            <label
              className="custom-control-label"
              htmlFor="parents_except_raising_child"
            >
              {SearchLabels.ParentsExceptRaisingChild}
            </label>
          </div>
        </FormGroup>
        <FormGroup check inline className="mr-3">
          <div className="custom-control custom-checkbox">
            <input
              {...register('family_except_raising_child')}
              className="custom-control-input"
              id="family_except_raising_child"
              type="checkbox"
            />
            <label
              className="custom-control-label"
              htmlFor="family_except_raising_child"
            >
              {SearchLabels.FamilyExceptRaisingChild}
            </label>
          </div>
        </FormGroup>
      </div>
    </Col>
  </Row>
)

export default FamilyStructure
