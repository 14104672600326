import { Labels } from 'common/enums'
import { createRegExpMessage } from 'common/utils'
import Feedback from 'components/atoms/Feedback/Feedback'
import React from 'react'
import { Controller } from 'react-hook-form'
import { Col, FormGroup, Input } from 'reactstrap'

type TURLInput = {
  name: string
  isDisabled?: boolean
  marginBottom?: number
  control: any
  errors?: any
}

const URLInput: React.FC<TURLInput> = ({
  name,
  isDisabled,
  marginBottom,
  control,
  errors,
}: TURLInput) => (
  <FormGroup className={marginBottom ? `mb-${marginBottom}` : ''} row>
    <Col sm={10}>
      <Controller
        name={name}
        control={control}
        rules={
          errors && {
            pattern: {
              value: /http:\/\/|https:\/\//,
              message: createRegExpMessage(Labels.Url),
            },
          }
        }
        render={({ field }) => (
          <Input
            type="text"
            disabled={isDisabled}
            className={errors && errors[name] ? 'is-invalid' : ''}
            placeholder="https://..."
            {...field}
          />
        )}
      />
      {errors && <Feedback error={errors[name]} />}
    </Col>
  </FormGroup>
)

export default URLInput
