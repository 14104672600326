import { Routes } from 'common/enums'
import { Condition } from 'common/types/condition'
import FormCard from 'components/organisms/Admin/ConditionForm/FormCard'
import React from 'react'
import { useLocation } from 'react-router'

const ConditionForm: React.FC = () => {
  const { pathname, state } = useLocation<Condition>()
  return (
    <FormCard
      isCreate={[
        Routes.ConditionGroupCreate,
        Routes.ConditionPatternCreate,
      ].includes(pathname as Routes)}
      isConditionGroup={[
        Routes.ConditionGroupCreate,
        Routes.ConditionGroupEdit,
      ].includes(pathname as Routes)}
      condition={state}
    />
  )
}

export default ConditionForm
