import { Routes } from 'common/enums'
import { IStoreCache } from 'common/types/auth_provider'
import { LoginForm } from 'common/types/form/login'
import { SimpleJWT } from 'common/types/simple_jwt'
import { getErrorMessage } from 'common/utils'
import { reloadCachedAccesstoken } from 'providers/AuthProvider'
import { createJWT } from 'repositories/auth'
import { modalService } from './modal'

/**
 * ログイン
 */
export const loginUser = async (
  { id, password }: LoginForm,
  storeCache: IStoreCache
): Promise<void> => {
  try {
    const { access, refresh }: SimpleJWT = await createJWT(id, password)

    localStorage.setItem('accesstoken', access)
    localStorage.setItem('refreshtoken', refresh)

    reloadCachedAccesstoken(storeCache)

    window.location.href = Routes.Home
  } catch (error) {
    getErrorMessage(error)
  }
}

/**
 * ログアウト
 */
export const logoutUser = (): void =>
  modalService.show('ログアウトしますが、よろしいですか？', () => {
    localStorage.removeItem('accesstoken')
    localStorage.removeItem('refreshtoken')
    localStorage.removeItem('home_form')
    localStorage.removeItem('home_result')

    window.location.href = Routes.Login
  })
