import React from 'react'
import { Button } from 'reactstrap'

type TOnSubmitButton = {
  content: string
}

const OnSubmitButton: React.FC<TOnSubmitButton> = ({
  content,
}: TOnSubmitButton) => (
  <div className="text-center">
    <Button color="primary" type="submit">
      {content}
    </Button>
  </div>
)

export default OnSubmitButton
