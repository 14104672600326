import { ButtonColor, Routes } from 'common/enums'
import { Case } from 'common/types/case'
import { SupportOrganization } from 'common/types/support_organization'
import { SupportSystem } from 'common/types/support_system'
import SmallButton from 'components/atoms/Button/SmallButton'
import { History } from 'history'
import React from 'react'

type TSearchResult = {
  title: string
  history: History
  pathname: Routes
  objs: Case[] | SupportSystem[] | SupportOrganization[]
}

const SearchResult: React.FC<TSearchResult> = ({
  title,
  history: { push },
  pathname,
  objs,
}: TSearchResult) => (
  <>
    <h4>
      <i className="fas fa-square-full" />
      {title}
    </h4>
    <div className="mb-4">
      {objs &&
        objs.map((state, key) => {
          return (
            <div key={key} className="mb-1">
              <SmallButton
                color={ButtonColor.INFO}
                content={state.name}
                onClick={() => push({ pathname, state })}
              />
            </div>
          )
        })}
    </div>
  </>
)

export default SearchResult
