import { Routes } from 'common/enums'
import OriginCard from 'components/atoms/Common/OriginCard'
import React from 'react'
import { useHistory } from 'react-router'
import { Button, Col, Row } from 'reactstrap'

type LinkRow = {
  key1: ButtonInfo
  key2: ButtonInfo
}

type ButtonInfo = {
  title: string
  route: Routes
}

const LinksCard: React.FC = () => {
  const { push } = useHistory()

  const rows: LinkRow[] = [
    {
      key1: { title: 'タグ管理', route: Routes.Tag },
      key2: { title: 'システム文言管理', route: Routes.SystemMessageForm },
    },
    {
      key1: { title: '支援制度管理', route: Routes.SupportSystem },
      key2: { title: '支援団体管理', route: Routes.SupportOrganization },
    },
    {
      key1: { title: '条件グループ管理', route: Routes.ConditionGroup },
      key2: { title: '条件パターン管理', route: Routes.ConditionPattern },
    },
    {
      key1: { title: 'メンバー管理', route: Routes.Member },
      key2: { title: '団体管理', route: Routes.Organization },
    },
    {
      key1: { title: '地域管理', route: Routes.Area },
      key2: { title: '権限管理', route: Routes.Permission },
    },
    {
      key1: { title: 'ログ管理', route: Routes.Log },
      key2: { title: 'ログイン履歴管理', route: Routes.LoggedIn },
    },
  ]

  return (
    <OriginCard title="管理メニュー（管理メンバー向け）">
      {rows.map(({ key1, key2 }, i) => (
        <Row key={i} className="mb-3">
          {colButton(key1, () => push(key1.route))}
          {colButton(key2, () => push(key2.route))}
        </Row>
      ))}
    </OriginCard>
  )
}

const colButton = ({ title }: ButtonInfo, onClick: () => void) => (
  <Col sm={6} className="text-center">
    <Button color="primary" block onClick={onClick}>
      {title}
    </Button>
  </Col>
)

export default LinksCard
