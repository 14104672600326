import { Labels, Routes } from 'common/enums'
import { TagForm } from 'common/types/form/tag'
import { Tag } from 'common/types/tag'
import {
  createMaxLengthMessage,
  createRequiredMessage,
  getTitle,
} from 'common/utils'
import OnSubmitButton from 'components/atoms/Button/OnSubmitButton'
import ButtonRight from 'components/atoms/Button/Right'
import OriginCard from 'components/atoms/Common/OriginCard'
import Feedback from 'components/atoms/Feedback/Feedback'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Form, FormGroup, Input } from 'reactstrap'
import { saveTag } from 'services/user/tag'

type TFormCard = {
  isCreate: boolean
  tag: Tag
}

const FormCard: React.FC<TFormCard> = ({ isCreate, tag }: TFormCard) => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const defaultValues = isCreate
    ? { name: '', sort_num: 0, member_id: storeCache.user!.id }
    : { name: tag.name, sort_num: tag.sort_num, member_id: storeCache.user!.id }
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TagForm>({ defaultValues })

  const save = (data: TagForm) =>
    saveTag(storeCache, history, isCreate, data, isCreate ? null : tag.id)

  return (
    <OriginCard title={`タグ${getTitle(isCreate, false)}`}>
      <ButtonRight
        history={history}
        nextPage={Routes.Tag}
        content={Labels.GoBack}
      />
      <Form role="form" onSubmit={handleSubmit(save)}>
        <h4>{Labels.TagName}</h4>
        <FormGroup className="mb-5">
          <Controller
            name="name"
            control={control}
            rules={{
              required: createRequiredMessage(Labels.TagName),
              maxLength: {
                value: 255,
                message: createMaxLengthMessage(Labels.TagName, 255),
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                list="name"
                className={errors.name && 'is-invalid'}
                {...field}
              />
            )}
          />
          <datalist id="name">
            {storeCache.tags.map(({ name }, i) => (
              <option key={i} value={name} />
            ))}
          </datalist>
          <Feedback error={errors.name} />
        </FormGroup>
        <h4>{Labels.SortNum}</h4>
        <FormGroup className="mb-5">
          <Controller
            name="sort_num"
            control={control}
            render={({ field }) => <Input type="number" min={0} {...field} />}
          />
          <Feedback error={errors.sort_num} />
        </FormGroup>
        <OnSubmitButton content={Labels.Save} />
      </Form>
    </OriginCard>
  )
}

export default FormCard
