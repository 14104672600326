import { IStoreCache } from 'common/types/auth_provider'
import { Area } from 'common/types/area'
import { Tag } from 'common/types/tag'
import { SupportOrganization } from 'common/types/support_organization'
import { SupportSystem } from 'common/types/support_system'
import { Condition } from 'common/types/condition'

const filterData = (datas: any[], findIds: number[]): any[] =>
  findIds
    .map((id) =>
      datas.length > 0 || !id
        ? datas.find((d) => d.id === id) ?? undefined
        : undefined
    )
    .filter((data) => data !== undefined)

/*
 * fillter tags
 */
export const filterTags = ({ tags }: IStoreCache, findIds: number[]): Tag[] =>
  filterData(tags, findIds)

/*
 * fillter areas
 */
export const filterAreas = (
  { areas }: IStoreCache,
  findIds: number[]
): Area[] => filterData(areas, findIds)

/*
 * fillter supportSystems
 */
export const filterSupportSystems = (
  { supportSystems }: IStoreCache,
  findIds: number[]
): SupportSystem[] => filterData(supportSystems, findIds)

/*
 * fillter supportOrganizations
 */
export const filterSupportOrganizations = (
  { supportOrganizations }: IStoreCache,
  findIds: number[]
): SupportOrganization[] => filterData(supportOrganizations, findIds)

/*
 * fillter conditionGroups
 */
export const filterConditionGroups = (
  { conditionGroups }: IStoreCache,
  findIds: number[]
): Condition[] => filterData(conditionGroups, findIds)

/*
 * find conditionGroup
 */
export const findConditionGroup = (
  { conditionGroups }: IStoreCache,
  findId: number
): Condition =>
  conditionGroups.find(({ id }) => id === findId) ?? ({} as Condition)

/*
 * find conditionPattern
 */
export const findConditionPattern = (
  { conditionPatterns }: IStoreCache,
  findId: number
): Condition =>
  conditionPatterns.find(({ id }) => id === findId) ?? ({} as Condition)
