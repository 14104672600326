export enum Routes {
  Login = '/login',
  TermsOfUse = '/terms_of_use',
  Home = '/home',
  Tag = '/tag',
  TagCreate = '/tag_create',
  TagEdit = '/tag_edit',
  Case = '/case',
  CaseCreate = '/case_create',
  CaseShow = '/case_show',
  CaseEdit = '/case_edit',
  SupportSystem = '/support_system',
  SupportSystemCreate = '/support_system_create',
  SupportSystemShow = '/support_system_show',
  SupportSystemEdit = '/support_system_edit',
  SupportOrganization = '/support_organization',
  SupportOrganizationCreate = '/support_organization_create',
  SupportOrganizationShow = '/support_organization_show',
  SupportOrganizationEdit = '/support_organization_edit',
  ManagementMenu = '/management_menu',
  Member = '/member',
  MemberCreate = '/member_create',
  MemberEdit = '/member_edit',
  Organization = '/organization',
  OrganizationCreate = '/organization_create',
  OrganizationEdit = '/organization_edit',
  Area = '/area',
  AreaCreate = '/area_create',
  AreaEdit = '/area_edit',
  Permission = '/permission',
  SystemMessageForm = '/system_message_form',
  ConditionGroup = '/condition_group',
  ConditionGroupCreate = '/condition_group_create',
  ConditionGroupEdit = '/condition_group_edit',
  ConditionPattern = '/condition_pattern',
  ConditionPatternCreate = '/condition_pattern_create',
  ConditionPatternEdit = '/condition_pattern_edit',
  Log = '/log',
  LoggedIn = '/logged_in',
}

export enum ViewType {
  LOGIN,
  LOADING,
  VISIBLE,
}

export enum AdminViewType {
  LOGIN,
  LOADING,
  USER,
  VISIBLE,
}

export enum AlertType {
  SUCCESS,
  DANGER,
}

export enum IncomeShowType {
  QUALIFICATION,
  OBLIGATION,
}

export enum LogActionType {
  CREATE = 'create',
  UPDATE = 'update',
  DESTROY = 'destroy',
}

export enum PageNames {
  HOME = 'Home',
}

export enum Labels {
  ID = 'ID',
  MemberUsername = '氏名',
  Email = 'メールアドレス',
  Password = 'パスワード',
  PasswordConfirm = 'パスワード（確認）',
  Area = '地域',
  Organization = '所属団体',
  Permission = '権限',
  SortNum = '並び順',
  BusinessDetail = '事業内容',
  Description = '詳細',
  ConditionGroup = '条件グループ',
  ConditionPatterns = '条件パターン',
  Url = 'URL',
  Tel = '電話番号',
  Address = '住所',
  OtherCombinedUnavailable = 'この制度を利用する場合に併用できない他の制度',
  PayAmountEtc = '支給額等',
  Application = '申請',
  ApplicationPeriod = '申請期限',
  Keywords = 'キーワード（複数）',
  Note = '備考',
  SearchCriteria = '検索条件',
  Status = '公開ステータス',
  RegisterStatus = '登録ステータス',
  Member = 'メンバー',
  Tag = 'タグ',
  TagName = 'タグ名',
  CaseName = '事例名',
  SupportSystemName = '支援制度名',
  SupportOrganizationName = '支援団体名',
  OrganizationName = '団体名',
  AreaName = '地域名',
  PermissionName = '権限名',
  PermissionPermissionSize = '権限の数値（低ければ権限が強い）',
  PhysicalisabilityCertificate = '身体障害者手帳',
  MentalDisabilityCertificate = '精神障害者保健福祉手帳',
  RaisingChildHandbook = '療育手帳',
  RaisingChildPersonTaxation = '養育者の課税対象',
  IsSingleParent = 'ひとり親か（母子家庭、父子家庭）',
  IsParentsExceptRaisingChild = '父母以外が養育か',
  IsFamilyExceptRaisingChild = '養育者以外に同居家族がいるか',
  SupportCount = '扶養人数（人）',
  IncomeMax = '所得上限（万円）',
  Other = 'その他',
  GoBack = '戻る',
  GoBackManagementMenu = '管理メニューに戻る',
  Create = '新規登録',
  Edit = '編集',
  Save = '保存',
  Delete = '削除',
  Search = '検索',
  Login = '同意して使用・ログインする',
  Person = '人',
  Yen = '円',
  ThousandYen = '万円',
}

export enum SearchLabels {
  FreeWord = 'フリーワード入力（スペース区切りで複数入力可）',
  Tags = 'タグ入力（複数入力できます）',
  BasicInfo = '基本情報',
  ParentResidence = '居住地（親）',
  ChildResidence = '居住地（子）',
  Age = '年齢',
  SchoolAttendance = '就学状況',
  ChildrenDisorderDegree = '子どもの障がいの程度',
  PhysicalDisabilityCertificateChild = '身体障害者手帳（子）',
  RaisingChildHandbookChild = '療育手帳（子）',
  MentalDisabilityCertificateChild = '精神障害者保健福祉手帳（子）',
  SpChildAllowanceSupportReceipt = '特別児童手当扶養受給',
  OtherChildrenFault = '子どもの障がいの程度その他',
  ParentDisorderDegree = '親の障がいの程度',
  ParentsWhichSevereFault = '父母どちらかが重度の障害を持っている',
  RaisingChildHandbookParent = '療育手帳（親）',
  PhysicalDisabilityCertificateParent = '身体障害者手帳（親）',
  MentalDisabilityCertificateParent = '精神障害者保健福祉手帳（親）',
  OtherParentFault = '親の障がいの程度その他',
  FamilyStructure = '家族構成',
  Mother = '母親',
  Father = '父親',
  ParentsExceptRaisingChild = '父母以外が養育',
  FamilyExceptRaisingChild = '養育者以外に同居家族がいる',
  SupportCount = '扶養人数',
  RaisingChildPersonTaxation = '道府県民税・市町村民税の所得割非課税（0円）',
  Income = '収入・所得の要件',
  RecipientQualificationPersonIncome = '父または母または養育者の「所得」（受給者資格者）',
  SupportObligationPersonIncome = '同居親族（扶養義務者）の「所得」',
  Abnormal = '家計急変・失業等',
  LivelihoodSubsidies = '生活保護を受けている',
}

export enum ButtonColor {
  DEFAULT = 'default',
  SECONDARY = 'secondary',
  INFO = 'info',
  DANGER = 'danger',
}

export enum LocalStorageKey {
  HOME_FORM = 'home_form',
  HOME_RESULT = 'home_result',
}
