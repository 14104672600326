import { Routes, ViewType } from 'common/enums'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import Footer from 'components/molecules/Footer/Footer'
import Sidebar from 'components/molecules/Sidebar/Sidebar'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router'
import { alertService } from 'services/alert'
import Case from 'template/User/Case'
import CaseForm from 'template/User/CaseForm'
import Home from 'template/User/Home'
import SupportOrganization from 'template/User/SupportOrganization'
import SupportOrganizationForm from 'template/User/SupportOrganizationForm'
import SupportSystem from 'template/User/SupportSystem'
import SupportSystemForm from 'template/User/SupportSystemForm'
import Tag from 'template/User/Tag'
import TagForm from 'template/User/TagForm'
import Admin from './Admin'

const User: React.FC = () => {
  const { accesstoken, isLoading } = useContext(AuthContext).storeCache
  const { replace } = useHistory()

  const [viewType, setViewType] = useState<ViewType>(ViewType.LOADING)

  useEffect(() => {
    if (isLoading) setViewType(ViewType.LOADING)
    else if (!accesstoken) setViewType(ViewType.LOGIN)
    else setViewType(ViewType.VISIBLE)
  }, [accesstoken, isLoading])

  switch (viewType) {
    case ViewType.LOGIN:
      alertService.show(
        false,
        '認証が正しくできていません。ログインしてください。'
      )
      replace(Routes.Login)
      return null
    case ViewType.LOADING:
      return <FullLoadingSpinner />
    case ViewType.VISIBLE:
      return routeSwitch()
  }
}

const routeSwitch = (): JSX.Element => (
  <>
    <Sidebar />

    <Switch>
      <Route path={Routes.Home} component={() => <Home />} exact />
      <Route path={Routes.Tag} component={() => <Tag />} exact />
      <Route
        path={[Routes.TagCreate, Routes.TagEdit]}
        component={() => <TagForm />}
        exact
      />
      <Route path={Routes.Case} component={() => <Case />} exact />
      <Route
        path={[Routes.CaseShow, Routes.CaseEdit]}
        component={() => <CaseForm />}
        exact
      />
      <Route
        path={Routes.SupportSystem}
        component={() => <SupportSystem />}
        exact
      />
      <Route
        path={[
          Routes.SupportSystemCreate,
          Routes.SupportSystemShow,
          Routes.SupportSystemEdit,
        ]}
        component={() => <SupportSystemForm />}
        exact
      />
      <Route
        path={Routes.SupportOrganization}
        component={() => <SupportOrganization />}
        exact
      />
      <Route
        path={[
          Routes.SupportOrganizationCreate,
          Routes.SupportOrganizationShow,
          Routes.SupportOrganizationEdit,
        ]}
        component={() => <SupportOrganizationForm />}
        exact
      />
      <Route path={adminPathList()} component={() => <Admin />} exact />
    </Switch>

    <Footer />
  </>
)

const adminPathList = (): Routes[] => {
  return [
    Routes.ManagementMenu,
    Routes.Member,
    Routes.MemberCreate,
    Routes.MemberEdit,
    Routes.Organization,
    Routes.OrganizationCreate,
    Routes.OrganizationEdit,
    Routes.Area,
    Routes.AreaCreate,
    Routes.AreaEdit,
    Routes.Permission,
    Routes.SystemMessageForm,
    Routes.ConditionGroup,
    Routes.ConditionGroupCreate,
    Routes.ConditionGroupEdit,
    Routes.ConditionPattern,
    Routes.ConditionPatternCreate,
    Routes.ConditionPatternEdit,
    Routes.Log,
    Routes.LoggedIn,
  ]
}

export default User
