import { Labels, PageNames, Routes } from 'common/enums'
import { HomeForm, HomeResult } from 'common/types/form/home'
import OnSubmitButton from 'components/atoms/Button/OnSubmitButton'
import SmallButton from 'components/atoms/Button/SmallButton'
import OriginCard from 'components/atoms/Common/OriginCard'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import BasicInfo from 'components/molecules/User/Home/BasicInfo'
import ChildrenDisorderDegree from 'components/molecules/User/Home/ChildrenDisorderDegree'
import FamilyStructure from 'components/molecules/User/Home/FamilyStructure'
import FreeWord from 'components/molecules/User/Home/Freeword'
import Income from 'components/molecules/User/Home/Income'
import ParentDisorderDegree from 'components/molecules/User/Home/ParentDisorderDegree'
import RaisingChildPersonTaxation from 'components/molecules/User/Home/RaisingChildPersonTaxation'
import SearchResult from 'components/molecules/User/Home/SearchResult'
import SupportCount from 'components/molecules/User/Home/SupportCount'
import { useAuthContext } from 'providers/AuthProvider'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Col, Form, FormGroup, Row } from 'reactstrap'
import {
  initHomeForm,
  initHomeResult,
  resetHomeForm,
  searchAndCreate,
} from 'services/user/home'

const SearchCard: React.FC = () => {
  const { storeCache } = useAuthContext()
  const history = useHistory()
  const [homeResult, setHomeResult] = useState<HomeResult>(initHomeResult())
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { control, register, handleSubmit, reset } = useForm<HomeForm>({
    defaultValues: initHomeForm(),
  })

  const resetForm = () => resetHomeForm(reset)

  const search = (data: HomeForm) =>
    searchAndCreate(storeCache, data, setHomeResult, setIsLoading)

  const { other_children_fault, other_parent_fault } = storeCache.systemMessage!

  if (isLoading) return <FullLoadingSpinner />

  return (
    <OriginCard title={PageNames.HOME}>
      <div className="mb-4">
        すべての項目を埋めず、空欄を残した状態でも検索をご利用いただけます。
        <br />
        （より多くの項目を入力いただくと検索の精度が高まります）
      </div>
      <div className="mb-4">
        <SmallButton
          color="primary"
          content="入力内容をリセットする"
          onClick={() => resetForm()}
        />
      </div>
      <Form role="form" onSubmit={handleSubmit(search)}>
        <FreeWord control={control} storeCache={storeCache} />
        <BasicInfo control={control} areas={storeCache.areas} />
        <ChildrenDisorderDegree
          control={control}
          register={register}
          otherChildrenFaultHtml={other_children_fault}
        />
        <ParentDisorderDegree
          control={control}
          register={register}
          otherParentFaultHtml={other_parent_fault}
        />
        <FamilyStructure register={register} />
        <SupportCount control={control} />
        <RaisingChildPersonTaxation register={register} />
        <Income control={control} register={register} />
        <div className="my-4">
          <FormGroup check inline className="mr-3">
            <div className="custom-control custom-checkbox">
              <input
                {...register('should_save')}
                className="custom-control-input"
                id="should_save"
                type="checkbox"
              />
              <label className="custom-control-label" htmlFor="should_save">
                この検索条件を事例として仮登録する
              </label>
            </div>
          </FormGroup>
        </div>
        <OnSubmitButton content={Labels.Search} />
      </Form>
      <hr />
      <SearchResult
        title="関連事例"
        history={history}
        pathname={Routes.CaseShow}
        objs={homeResult.cases}
      />
      <Row>
        <Col sm={6}>
          <SearchResult
            title="支援制度候補"
            history={history}
            pathname={Routes.SupportSystemShow}
            objs={homeResult.support_system}
          />
        </Col>
        <Col sm={6}>
          <SearchResult
            title="支援団体候補"
            history={history}
            pathname={Routes.SupportOrganizationShow}
            objs={homeResult.support_organization}
          />
        </Col>
      </Row>
    </OriginCard>
  )
}

export default SearchCard
