import axios from 'axios'

const client = () =>
  axios.create({
    baseURL: '/api',
    headers: { 'Content-Type': 'application/json' },
  })

/**
 * Request Method: GET
 */
export const get = async <T>(
  accesstoken: string,
  url: string,
  params: any = {}
): Promise<T> => {
  const headers = { Authorization: `JWT ${accesstoken}` }
  return (await client().get<T>(url, { headers, params })).data
}

/**
 * Request Method: POST
 */
export const post = async <T>(
  accesstoken: string,
  url: string,
  data: any = {},
  token?: string
): Promise<T> => {
  const headers = {
    Authorization: `JWT ${accesstoken}`,
    'X-CSRFToken': token ?? '',
  }
  return (await client().post<T>(url, data, { headers })).data
}

/**
 * Request Method: PATCH
 */
export const patch = async <T>(
  accesstoken: string,
  url: string,
  data: any = {}
): Promise<T> => {
  const headers = { Authorization: `JWT ${accesstoken}` }
  return (await client().patch<T>(url, data, { headers })).data
}

/**
 * Request Method: DELETE
 */
export const destroy = async <T>(
  accesstoken: string,
  url: string,
  params: any = {}
): Promise<T> => {
  const headers = { Authorization: `JWT ${accesstoken}` }
  return (await client().delete(url, { headers, params })).data
}
