import { Routes } from 'common/enums'
import {
  filterConditionGroups,
  filterSupportSystems,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/types/auth_provider'
import { HomeForm } from 'common/types/form/home'
import {
  OptionsGroup,
  SupportSystemForm,
} from 'common/types/form/support_system'
import { SupportSystem } from 'common/types/support_system'
import {
  createSelectOptions,
  createSelectOptionsForCondition,
  createSelectOptionsForLabel,
  formatSelectOptions,
  getErrorMessage,
} from 'common/utils'
import { History } from 'history'
import {
  reloadCachedSupportSystems,
  reloadCachedSupportSystemsAndConditionGroups,
} from 'providers/AuthProvider'
import { create, remove, update } from 'repositories/support_system'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * 支援制度フォーム初期値設定
 */
export const initSupportSystem = (
  storeCache: IStoreCache,
  ss: SupportSystem,
  isCreate: boolean
): SupportSystemForm =>
  isCreate
    ? {
        id: 0,
        name: '',
        description: '',
        url: '',
        other_combined_unavailable: [],
        pay_amount_etc: [],
        application: '',
        application_period: '',
        condition_groups: [],
        status: true,
        note: '',
        search_criteria: {} as HomeForm,
        member_id: storeCache.user!.id,
      }
    : {
        id: ss.id,
        name: ss.name,
        description: ss.description,
        url: ss.url,
        other_combined_unavailable: createSelectOptions(
          filterSupportSystems(storeCache, ss.other_combined_unavailable)
        ) as any[],
        pay_amount_etc: createSelectOptionsForLabel(ss.pay_amount_etc),
        application: ss.application,
        application_period: ss.application_period,
        condition_groups: createSelectOptionsForCondition(
          ss.condition_groups
            ? filterConditionGroups(storeCache, ss.condition_groups)
            : [],
          true
        ) as any,
        status: ss.status,
        note: ss.note,
        search_criteria: ss.search_criteria as HomeForm,
        member_id: storeCache.user!.id,
      }

/**
 *  react-selectの選択肢生成データの一元化
 */
export const getOptionsGroup = (
  storeCache: IStoreCache,
  supportSystem?: SupportSystem
): OptionsGroup => ({
  supportSystemOptions: createSelectOptions(
    supportSystem
      ? storeCache.supportSystems.filter((s) => supportSystem.id !== s.id)
      : storeCache.supportSystems
  ),
  conditionGroupOptions: createSelectOptionsForCondition(
    storeCache.conditionGroups,
    true
  ),
})

/**
 * 支援制度を作成 or 更新する
 */
export const saveSupportSystem = async (
  storeCache: IStoreCache,
  { push }: History,
  isCreate: boolean,
  data: SupportSystemForm,
  id: number | null
): Promise<void> => {
  try {
    if (
      !isCreate &&
      storeCache.supportSystems.some(
        ({ id: pk, name }) => pk !== id && name === data.name
      )
    )
      throw new Error('既に同じ名前の支援制度が登録されてます。')

    data.other_combined_unavailable = formatSelectOptions(
      data.other_combined_unavailable
    )
    data.pay_amount_etc = formatSelectOptions(data.pay_amount_etc)
    data.condition_groups = formatSelectOptions(data.condition_groups)
    const { name } = isCreate
      ? await create(storeCache.accesstoken, data)
      : await update(storeCache.accesstoken, data, id!)

    await reloadCachedSupportSystemsAndConditionGroups(storeCache)

    alertService.show(true, `${name}の保存が完了しました。`)
    push(Routes.SupportSystem)
  } catch (error) {
    getErrorMessage(error)
  }
}

/**
 * 支援制度を削除する
 */
export const deleteSupportSystem = (
  storeCache: IStoreCache,
  { name, id }: SupportSystem
): void => {
  try {
    modalService.show(`${name}を削除しますか？`, async () => {
      await remove(storeCache.accesstoken, id, storeCache.user!.id)
      await reloadCachedSupportSystems(storeCache)
      alertService.show(true, `${name}の削除が完了しました。`)
    })
  } catch (error) {
    getErrorMessage(error)
  }
}
