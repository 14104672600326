import {
  PERMISSION_NOMAL_MAX_NUM,
  PERMISSION_SEMI_ADMIN_MAX_NUM,
} from 'common/const'
import { Member } from 'common/types/member'

const hasPermissionObj = (member: Member | null): boolean =>
  member === null || member.permission_obj === null

/**
 * 権限にて、運営管理者以下かどうか
 */
export const isSemiAdminOrLess = (member: Member | null): boolean =>
  hasPermissionObj(member) ||
  member!.permission_obj!.permission_size >= PERMISSION_SEMI_ADMIN_MAX_NUM

/**
 * 権限にて、通常ユーザ以下かどうか
 */
export const isNormalUserOrLess = (member: Member | null): boolean =>
  hasPermissionObj(member) ||
  member!.permission_obj!.permission_size >= PERMISSION_NOMAL_MAX_NUM
