import { Routes } from 'common/enums'
import TableCard from 'components/organisms/Admin/ConditionList/TableCard'
import React from 'react'
import { useLocation } from 'react-router-dom'

const ConditionList: React.FC = () => {
  const { pathname } = useLocation()
  return <TableCard isConditionGroup={pathname === Routes.ConditionGroup} />
}

export default ConditionList
