import { destroy, get, patch, post } from 'common/fetch'
import { OrganizationForm } from 'common/types/form/organization'
import { Organization } from 'common/types/organization'

const API_BASE_URL = '/organization/'

/**
 * 所属団体を全て取得する
 */
export const fetch = async (accesstoken: string): Promise<Organization[]> =>
  await get<Organization[]>(accesstoken, API_BASE_URL)

/**
 * 所属団体を新規登録する
 */
export const create = async (
  accesstoken: string,
  data: OrganizationForm
): Promise<Organization> =>
  await post<Organization>(accesstoken, API_BASE_URL, data)

/**
 * 所属団体を更新する
 */
export const update = async (
  accesstoken: string,
  data: OrganizationForm,
  id: number
): Promise<Organization> =>
  await patch<Organization>(accesstoken, `${API_BASE_URL}${id}/`, data)

/**
 * 所属団体を削除する
 */
export const remove = async (
  accesstoken: string,
  id: number,
  member_id: number
): Promise<void> =>
  await destroy<void>(accesstoken, `${API_BASE_URL}${id}/`, { member_id })
