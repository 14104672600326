import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { Col, FormGroup } from 'reactstrap'

type TCheckbox = {
  id: string
  name?: string
  label: string
  disabled?: boolean
  marginBottom?: number
  register: UseFormRegister<any>
}

const Checkbox: React.FC<TCheckbox> = ({
  id,
  name,
  label,
  disabled,
  marginBottom,
  register,
}: TCheckbox) => (
  <FormGroup
    className={`d-flex align-items-center ${
      marginBottom ? `mb-${marginBottom}` : ''
    }`}
    row
  >
    <Col sm={12}>
      <div className="custom-control custom-checkbox">
        <input
          {...register(name ?? id)}
          disabled={disabled}
          className="custom-control-input"
          id={id}
          type="checkbox"
        />
        <label className="custom-control-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </Col>
  </FormGroup>
)

export default Checkbox
