import { get } from 'common/fetch'
import { Permission } from 'common/types/permission'

const API_BASE_URL = '/permission/'

/**
 * 権限を全て取得する
 */
export const fetch = async (
  accesstoken: string,
  member_id: number | null = null
): Promise<Permission[]> =>
  await get<Permission[]>(accesstoken, API_BASE_URL, { member_id })
