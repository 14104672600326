import React from 'react'
import { Button, Row } from 'reactstrap'

type TRightDoubleButton = {
  leftOnClick: () => void
  rightOnClick: () => void
  leftContent: string
  rightContent: string
}

const RightDoubleButton: React.FC<TRightDoubleButton> = ({
  leftOnClick,
  rightOnClick,
  leftContent,
  rightContent,
}: TRightDoubleButton) => (
  <Row className="justify-content-end mb-3 mr-1 mr-md-0">
    <Button onClick={leftOnClick} color="default" size="sm" type="button">
      {leftContent}
    </Button>
    <Button onClick={rightOnClick} color="secondary" size="sm" type="button">
      {rightContent}
    </Button>
  </Row>
)

export default RightDoubleButton
