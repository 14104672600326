import { SearchLabels } from 'common/enums'
import { Area } from 'common/types/area'
import { HomeForm } from 'common/types/form/home'
import {
  generateAges,
  generateSchoolAttendances,
  selectOptions,
} from 'components/atoms/Select/Options'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'

type TBasicInfo = {
  control: Control<HomeForm, object>
  areas: Area[]
}

const BasicInfo: React.FC<TBasicInfo> = ({ control, areas }: TBasicInfo) => (
  <Row className="mb-3">
    <Col sm="12">
      <h4>
        <i className="fas fa-square-full" />
        {SearchLabels.BasicInfo}
      </h4>
      <FormGroup className="d-flex align-items-center my-4" row>
        <Col sm={3}>
          <Label>{SearchLabels.ParentResidence}</Label>
          <Controller
            name="parent_residence"
            control={control}
            render={({ field }) => (
              <Input type="select" {...field}>
                {selectOptions(areas)}
              </Input>
            )}
          />
        </Col>
        <Col sm={3}>
          <Label>{SearchLabels.ChildResidence}</Label>
          <Controller
            name="child_residence"
            control={control}
            render={({ field }) => (
              <Input type="select" {...field}>
                {selectOptions(areas)}
              </Input>
            )}
          />
        </Col>
        <Col sm={3}>
          <Label>{SearchLabels.Age}</Label>
          <Controller
            name="age"
            control={control}
            render={({ field }) => (
              <Input type="select" {...field}>
                {generateAges()}
              </Input>
            )}
          />
        </Col>
        <Col sm={3}>
          <Label>{SearchLabels.SchoolAttendance}</Label>
          <Controller
            name="school_attendance"
            control={control}
            render={({ field }) => (
              <Input type="select" {...field}>
                {generateSchoolAttendances()}
              </Input>
            )}
          />
        </Col>
      </FormGroup>
    </Col>
  </Row>
)

export default BasicInfo
