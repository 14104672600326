import {
  ages,
  HANDBOOK_TYPES,
  RECEIPTS,
  SCHOOL_ATTENDANCES,
} from 'common/const'
import { Routes } from 'common/enums'
import { IStoreCache } from 'common/types/auth_provider'
import { Condition } from 'common/types/condition'
import {
  ConditionArray,
  ConditionItem,
} from 'common/types/form/condition_pattern'
import {
  convertConditionTitle,
  createSelectOptions,
  createSelectOptionsForArr,
  formatSelectOptions,
  formatYesNo,
  generateContent,
  getErrorMessage,
} from 'common/utils'
import { History } from 'history'
import {
  reloadCachedConditionGroups,
  reloadCachedConditionPatterns,
} from 'providers/AuthProvider'
import {
  Control,
  useFieldArray,
  UseFieldArrayAppend,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form'
import { OptionsOrGroups } from 'react-select'
import {
  create as createConditionGroup,
  remove as removeConditionGroup,
  update as updateConditionGroup,
} from 'repositories/condition_group'
import {
  create as createConditionPattern,
  remove as removeConditionPattern,
  update as updateConditionPattern,
} from 'repositories/condition_pattern'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * 検索条件の初期値生成
 */
export const initConditionItem = (): ConditionItem => ({
  label: '',
  content: '',
  connection: '',
})

/**
 * 条件パターン・グループの初期値設定フォーム生成
 */
export const useInitConditionForm = (
  storeCache: IStoreCache,
  condition: Condition | undefined
): {
  handleSubmit: UseFormHandleSubmit<ConditionArray>
  register: UseFormRegister<ConditionArray>
  append: UseFieldArrayAppend<ConditionArray, 'items'>
  remove: (index?: number | number[] | undefined) => void
  control: Control<ConditionArray, object> | undefined
  controlledFields: ConditionItem[]
} => {
  const defaultValues: ConditionArray = {
    name: condition?.name ?? '',
    items: [],
  }
  if (condition?.query)
    for (const { label, content, connection } of condition?.query)
      defaultValues.items.push({
        label,
        content: generateContent(storeCache, label, content),
        connection,
      })

  console.log({ defaultValues })

  const { control, handleSubmit, register, watch } = useForm<ConditionArray>({
    defaultValues,
  })
  const { fields, append, remove } = useFieldArray({ name: 'items', control })

  const watchFieldArray = watch('items')
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }))

  return { handleSubmit, register, append, remove, control, controlledFields }
}

export type OptionsGroup = {
  conditionGroupOptions: OptionsOrGroups<string, any>
  conditionPatternOptions: OptionsOrGroups<string, any>
  tagOptions: OptionsOrGroups<string, any>
  areaOptions: OptionsOrGroups<string, any>
  ageOptions: OptionsOrGroups<string, any>
  schoolAttendanceOptions: OptionsOrGroups<string, any>
  handbookTypeOptions: OptionsOrGroups<string, any>
  receiptOptions: OptionsOrGroups<string, any>
}

/**
 *  react-selectの選択肢生成データの一元化
 */
export const getOptionsGroup = (
  { conditionGroups, conditionPatterns, tags, areas }: IStoreCache,
  condition: Condition | undefined
): OptionsGroup => ({
  conditionGroupOptions: createSelectOptions(
    conditionGroups.filter((cg) => cg.id !== condition?.id)
  ),
  conditionPatternOptions: createSelectOptions(conditionPatterns),
  tagOptions: createSelectOptions(tags),
  areaOptions: createSelectOptions(areas),
  ageOptions: createSelectOptionsForArr(ages()),
  schoolAttendanceOptions: createSelectOptionsForArr(SCHOOL_ATTENDANCES),
  handbookTypeOptions: createSelectOptionsForArr(HANDBOOK_TYPES),
  receiptOptions: createSelectOptionsForArr(RECEIPTS),
})

/**
 * 検索条件グループまたは、パターンを作成 or 更新する
 */
export const saveCondition = async (
  storeCache: IStoreCache,
  { push }: History,
  isCreate: boolean,
  isConditionGroup: boolean,
  data: ConditionArray,
  id: number | null
): Promise<void> => {
  try {
    validation(data)

    const query: ConditionItem[] = data.items.map(
      ({ label, content, connection }) => {
        let contentObj = content
        if (isSingleSelect(label)) contentObj = contentObj.value
        if (isMultiSelect(label)) contentObj = formatSelectOptions(contentObj)
        if (isRadio(label)) contentObj = formatYesNo(contentObj)
        return { label, content: contentObj, connection }
      }
    )

    const condition = isCreate
      ? { id: 0, name: data.name, query }
      : { id: id!, name: data.name, query }
    if (isConditionGroup) {
      isCreate
        ? await createConditionGroup(storeCache.accesstoken, condition)
        : await updateConditionGroup(storeCache.accesstoken, condition, id!)
      await reloadCachedConditionGroups(storeCache)
    } else {
      isCreate
        ? await createConditionPattern(storeCache.accesstoken, condition)
        : await updateConditionPattern(storeCache.accesstoken, condition, id!)
      await reloadCachedConditionPatterns(storeCache)
    }

    alertService.show(
      true,
      `検索${convertConditionTitle(isConditionGroup)}の保存が完了しました。`
    )
    push(isConditionGroup ? Routes.ConditionGroup : Routes.ConditionPattern)
  } catch (error) {
    getErrorMessage(error)
  }
}

const validation = ({ items }: ConditionArray): void => {
  const itemLength = items.length
  if (itemLength === 0) throw new Error('一つ以上の検索条件を設定してください')
  if (itemLength > 0 && !!items[itemLength - 1].connection)
    throw new Error('最後の検索条件にAND ORは値を設定できません')
  if (items.some(({ label, content }) => !label || !content))
    throw new Error('設定されてない検索条件があるので保存できません')

  const nonLastItems = items.slice(0, -1)
  if (nonLastItems.some(({ connection }) => !connection))
    throw new Error('AND ORが設定されてない検索条件があります')

  let connections = items
    .filter(({ connection }) => connection)
    .map(({ connection }) => connection)
  connections = Array.from(new Set(connections))
  if (connections.length > 1)
    throw new Error('一つの条件にはAND ORのどちらか一つしか選択できません')
}

const isSingleSelect = (label: string) =>
  ['condition_groups', 'condition_patterns'].includes(label)

const isMultiSelect = (label: string) =>
  [
    'tags',
    'parent_residence',
    'child_residence',
    'age',
    'school_attendance',
    'physical_disability_certificate_child',
    'physical_disability_certificate_parent',
    'raising_child_handbook_child',
    'raising_child_handbook_parent',
    'mental_disability_certificate_child',
    'mental_disability_certificate_parent',
    'sp_child_allowance_support_receipt',
    'keyword',
  ].includes(label)

const isRadio = (label: string) =>
  [
    'parents_which_severe_fault',
    'other_children_fault',
    'other_parent_fault',
    'is_single_parent',
    'is_parents_except_raising_child',
    'is_family_except_raising_child',
    'is_abnormal',
    'is_livelihood_subsidies',
    'is_raising_child_person_taxation',
  ].includes(label)

/**
 * 検索条件パターンを削除する
 */
export const deleteConditionPattern = (
  storeCache: IStoreCache,
  isConditionGroup: boolean,
  { id }: Condition
): void => {
  try {
    const conditionTitle = `${convertConditionTitle(isConditionGroup)}${id}`
    modalService.show(`${conditionTitle}を削除しますか？`, async () => {
      const { accesstoken, user } = storeCache
      if (isConditionGroup) {
        await removeConditionGroup(accesstoken, id, user!.id)
        await reloadCachedConditionGroups(storeCache)
      } else {
        await removeConditionPattern(accesstoken, id, user!.id)
        await reloadCachedConditionPatterns(storeCache)
      }

      alertService.show(true, `${conditionTitle}の削除が完了しました。`)
    })
  } catch (error) {
    getErrorMessage(error)
  }
}
