import React from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import Quill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'styles/quill.scss'

type TNomalQuill = {
  className?: string
  field: ControllerRenderProps<any, any>
}

const NomalQuill: React.FC<TNomalQuill> = ({
  field,
  className,
}: TNomalQuill) => <Quill className={className} {...field} />

export default NomalQuill
