import React from 'react'
import { UseFormRegister } from 'react-hook-form'

type TCheckbox = {
  name: string
  isFalse: boolean
  trueMessage: string
  falseMessage: string
  marginBottom?: number
  register: UseFormRegister<any>
}

const YesNo: React.FC<TCheckbox> = ({
  name,
  isFalse,
  trueMessage,
  falseMessage,
  marginBottom,
  register,
}: TCheckbox) => (
  <div className={`${marginBottom ? `mb-${marginBottom}` : ''}`}>
    <div className="custom-control custom-radio">
      <input
        {...register(name)}
        className="custom-control-input"
        id={`${name}-1`}
        name={name}
        value="true"
        type="radio"
        defaultChecked={!isFalse}
      />
      <label className="custom-control-label" htmlFor={`${name}-1`}>
        {trueMessage}
      </label>
    </div>
    <div className="custom-control custom-radio">
      <input
        {...register(name)}
        className="custom-control-input"
        id={`${name}-2`}
        name={name}
        value="false"
        type="radio"
        defaultChecked={isFalse}
      />
      <label className="custom-control-label" htmlFor={`${name}-2`}>
        {falseMessage}
      </label>
    </div>
  </div>
)

export default YesNo
