import { SearchLabels } from 'common/enums'
import { HomeForm } from 'common/types/form/home'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Col, FormGroup, Input, Row } from 'reactstrap'

type TSupportCount = {
  control: Control<HomeForm, object>
}

const SupportCount: React.FC<TSupportCount> = ({ control }: TSupportCount) => (
  <Row className="mb-3">
    <Col sm="12">
      <h4>
        <i className="fas fa-square-full" />
        {SearchLabels.SupportCount}
      </h4>
      <FormGroup className="d-flex align-items-center my-4" row>
        <Col sm={3}>
          <Controller
            name="support_count"
            control={control}
            render={({ field }) => <Input type="number" min={0} {...field} />}
          />
        </Col>
      </FormGroup>
    </Col>
  </Row>
)

export default SupportCount
