import React from 'react'
import { NavItem, NavLink } from 'reactstrap'
import { logoutUser } from 'services/login'

export enum ItemActionType {
  LOGOUT,
}

interface IItemAction {
  action: ItemActionType
}

const ItemAction: React.FC<IItemAction> = ({ action }: IItemAction) => (
  <NavItem>
    <NavLink onClick={() => doAction(action)} style={{ cursor: 'pointer' }}>
      {getContent(action)}
    </NavLink>
  </NavItem>
)

const getContent = (action: ItemActionType): string =>
  action === ItemActionType.LOGOUT ? 'ログアウト' : ''

const doAction = (action: ItemActionType) => {
  if (action === ItemActionType.LOGOUT) logoutUser()
}

export default ItemAction
