import { Routes } from 'common/enums'
import TableCard from 'components/organisms/User/Tag/TableCard'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { isNormalUserOrLess } from 'services/auth'

const Tag: React.FC = () => {
  const { user } = useContext(AuthContext).storeCache
  const { replace } = useHistory()

  if (isNormalUserOrLess(user)) replace(Routes.Home)

  return <TableCard />
}

export default Tag
