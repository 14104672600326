import React from 'react'
import { Button, Modal } from 'reactstrap'

type TCancelOnlyModal = {
  content: string
  modalElement: string
  marginX?: string
  isOpen: boolean
  setIsOpen: () => any
}

const CancelOnlyModal: React.FC<TCancelOnlyModal> = ({
  content,
  modalElement,
  marginX,
  isOpen,
  setIsOpen,
}: TCancelOnlyModal) => (
  <>
    <span
      className={marginX ? `mx-${marginX}` : ''}
      data-placement="top"
      style={{
        fontSize: 20,
        color: 'lightblue',
        borderBottom: '1px solid lightblue',
      }}
      onClick={setIsOpen}
    >
      {content}
    </span>
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={setIsOpen}>
      <div className="modal-header">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={setIsOpen}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div
        className="modal-body"
        dangerouslySetInnerHTML={{ __html: modalElement }}
      />
      <div className="modal-footer">
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={setIsOpen}
        >
          閉じる
        </Button>
      </div>
    </Modal>
  </>
)

export default CancelOnlyModal
