import { Labels, PageNames, Routes, SearchLabels } from 'common/enums'
import { SystemMessage } from 'common/types/system_message'
import { createMaxLengthMessage, createRequiredMessage } from 'common/utils'
import OnSubmitButton from 'components/atoms/Button/OnSubmitButton'
import ButtonRight from 'components/atoms/Button/Right'
import OriginCard from 'components/atoms/Common/OriginCard'
import Feedback from 'components/atoms/Feedback/Feedback'
import NomalQuill from 'components/atoms/Quill/NomalQuill'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Form, FormGroup } from 'reactstrap'
import { saveSystemMessage } from 'services/user/system_message'

const FormCard: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const defaultValues = storeCache.systemMessage!
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SystemMessage>({ defaultValues })

  const save = (data: SystemMessage) => saveSystemMessage(storeCache, data)

  return (
    <OriginCard title="システム文言管理">
      <ButtonRight
        history={history}
        nextPage={Routes.ManagementMenu}
        content={Labels.GoBack}
      />
      <Form role="form" onSubmit={handleSubmit(save)}>
        <h2>{PageNames.HOME}</h2>
        <h4>{SearchLabels.OtherChildrenFault}</h4>
        <FormGroup className="mb-5">
          <Controller
            name="other_children_fault"
            control={control}
            rules={{
              required: createRequiredMessage(SearchLabels.OtherChildrenFault),
              maxLength: {
                value: 100000,
                message: createMaxLengthMessage(
                  SearchLabels.OtherChildrenFault,
                  100000
                ),
              },
            }}
            render={({ field }) => (
              <NomalQuill
                className={errors.other_children_fault && 'is-invalid'}
                field={field}
              />
            )}
          />
          <Feedback error={errors.other_children_fault} />
        </FormGroup>
        <h4>{SearchLabels.OtherParentFault}</h4>
        <FormGroup className="mb-5">
          <Controller
            name="other_parent_fault"
            control={control}
            rules={{
              required: createRequiredMessage(SearchLabels.OtherParentFault),
              maxLength: {
                value: 100000,
                message: createMaxLengthMessage(
                  SearchLabels.OtherParentFault,
                  100000
                ),
              },
            }}
            render={({ field }) => (
              <NomalQuill
                className={errors.other_children_fault && 'is-invalid'}
                field={field}
              />
            )}
          />
          <Feedback error={errors.other_parent_fault} />
        </FormGroup>
        <OnSubmitButton content={Labels.Save} />
      </Form>
    </OriginCard>
  )
}

export default FormCard
