import { History } from 'history'
import React from 'react'
import { Button, Row } from 'reactstrap'

type TButtonRight = {
  history: History
  nextPage?: string
  content: string
}

const ButtonRight: React.FC<TButtonRight> = ({
  history: { goBack, push },
  nextPage,
  content,
}: TButtonRight) => (
  <Row className="justify-content-end mb-3 mr-1 mr-md-0">
    <Button
      onClick={() => (nextPage ? push(nextPage) : goBack())}
      color="default"
      size="sm"
      type="button"
    >
      {content}
    </Button>
  </Row>
)

export default ButtonRight
