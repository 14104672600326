import { LocalStorageKey } from 'common/enums'
import { IStoreCache } from 'common/types/auth_provider'
import { CaseForm } from 'common/types/form/case'
import { HomeForm, HomeResult } from 'common/types/form/home'
import { formatSelectOptions, getErrorMessage } from 'common/utils'
import { reloadCachedCases } from 'providers/AuthProvider'
import { UseFormReset } from 'react-hook-form'
import { create as createCase, fetch as fetchCases } from 'repositories/case'
import { fetch as fetchSupportOrganizations } from 'repositories/support_organization'
import { fetch as fetchSupportSystems } from 'repositories/support_system'
import { alertService } from 'services/alert'

/**
 * 検索フォーム初期値設定
 */
export const initHomeForm = (): HomeForm => {
  const homeForm = localStorage.getItem(LocalStorageKey.HOME_FORM)
  const result: HomeForm = homeForm
    ? JSON.parse(homeForm)
    : {
        free_word: '',
        tags: [],
        parent_residence: '',
        child_residence: '',
        age: '',
        school_attendance: '',
        physical_disability_certificate_child: '',
        raising_child_handbook_child: '',
        mental_disability_certificate_child: '',
        sp_child_allowance_support_receipt: '',
        other_children_fault: false,
        physical_disability_certificate_parent: '',
        raising_child_handbook_parent: '',
        mental_disability_certificate_parent: '',
        parents_which_severe_fault: false,
        other_parent_fault: false,
        mother: false,
        father: false,
        parents_except_raising_child: false,
        family_except_raising_child: false,
        raising_child_person_taxation: false,
        abnormal: false,
        livelihood_subsidies: false,
        should_save: false,
      }

  result.should_save = false
  return result
}

/**
 * 検索フォーム初期値化
 */
export const resetHomeForm = (reset: UseFormReset<HomeForm>): void => {
  localStorage.removeItem(LocalStorageKey.HOME_FORM)
  reset({ ...initHomeForm() })
}

/**
 * HomeResult初期値
 */
export const initHomeResult = (): HomeResult => {
  const homeResult = localStorage.getItem(LocalStorageKey.HOME_RESULT)
  return homeResult
    ? JSON.parse(homeResult)
    : { cases: [], support_system: [], support_organization: [] }
}

/**
 * 検索を行い、事例を仮で作成する
 */
export const searchAndCreate = async (
  storeCache: IStoreCache,
  data: HomeForm,
  setHomeResult: React.Dispatch<React.SetStateAction<HomeResult>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  setIsLoading(true)
  try {
    localStorage.setItem(LocalStorageKey.HOME_FORM, JSON.stringify(data))

    const {
      support_count,
      support_obligation_person_income,
      recipient_qualification_person_income,
      tags,
      should_save,
    } = data
    if (
      (support_obligation_person_income &&
        Number.isNaN(+support_obligation_person_income)) ||
      (recipient_qualification_person_income &&
        Number.isNaN(+recipient_qualification_person_income))
    )
      throw new Error('所得金額は数値で入力してください')
    if (
      (!support_count &&
        support_obligation_person_income &&
        support_obligation_person_income > 0) ||
      (!support_count &&
        recipient_qualification_person_income &&
        recipient_qualification_person_income > 0)
    )
      throw new Error('扶養人数を入力してください')

    data.tags = formatSelectOptions(tags)

    const { accesstoken, user } = storeCache

    // 検索結果取得
    const [support_system, support_organization] = await Promise.all([
      fetchSupportSystems(accesstoken, data),
      fetchSupportOrganizations(accesstoken, data),
    ])
    const support_organizations = support_organization.map(({ id }) => id) ?? []
    const support_systems = support_system.map(({ id }) => id) ?? []
    const cases = await fetchCases(accesstoken, {
      free_word: '',
      tags: data.tags ?? [],
      support_systems,
      support_organizations,
    })

    const homeResult = { cases, support_system, support_organization }

    setHomeResult(homeResult)
    localStorage.setItem(
      LocalStorageKey.HOME_RESULT,
      JSON.stringify(homeResult)
    )

    // 仮登録する場合、検索結果を事例エンティティに保存
    if (should_save) {
      if (support_count === undefined) data.support_count = 0

      const search_criteria: any = data
      delete search_criteria.should_save

      const caseForm: CaseForm = {
        name: '',
        description: '',
        tags,
        support_organizations,
        support_systems,
        search_criteria,
        register_status: 0,
        member: user!.id,
      }

      await createCase(accesstoken, caseForm)
      await reloadCachedCases(storeCache)

      alertService.show(true, 'この検索条件を事例として仮登録しました。')
    }
    if (
      homeResult.support_organization.length === 0 &&
      homeResult.support_system.length === 0
    )
      alertService.show(false, 'ご指定の条件で利用できる制度が見つかりません。')
  } catch (error) {
    getErrorMessage(error)
  }
  setIsLoading(false)
}
