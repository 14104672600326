import React from 'react'
import { Button } from 'reactstrap'

type TSmallButton = {
  color: string
  content: string
  onClick: () => any
}

const SmallButton: React.FC<TSmallButton> = ({
  color,
  content,
  onClick,
}: TSmallButton) => (
  <Button onClick={onClick} color={color} size="sm" type="button">
    {content}
  </Button>
)

export default SmallButton
