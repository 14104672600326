import React from 'react'
import { Control, Controller } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'
import { FormGroup } from 'reactstrap'

type TCreateSelect = {
  name: string
  label?: string
  isDisabled?: boolean
  marginBottom?: number
  control: Control<any, object>
}

const CreateSelect: React.FC<TCreateSelect> = ({
  name,
  label,
  isDisabled,
  marginBottom,
  control,
}: TCreateSelect) => (
  <FormGroup className={marginBottom ? `mb-${marginBottom}` : ''}>
    {label && <h4>{label}</h4>}
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CreatableSelect
          closeMenuOnSelect={false}
          isClearable
          isMulti
          isDisabled={isDisabled}
          {...field}
        />
      )}
    />
  </FormGroup>
)

export default CreateSelect
