import { Labels, SearchLabels } from 'common/enums'
import { HomeForm } from 'common/types/form/home'
import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { Col, FormGroup, Row } from 'reactstrap'

type TRaisingChildPersonTaxation = {
  register: UseFormRegister<HomeForm>
}
const RaisingChildPersonTaxation: React.FC<TRaisingChildPersonTaxation> = ({
  register,
}: TRaisingChildPersonTaxation) => (
  <Row className="mb-3">
    <Col sm="12">
      <h4>
        <i className="fas fa-square-full" />
        {Labels.RaisingChildPersonTaxation}
      </h4>
      <FormGroup className="d-flex align-items-center my-3" row>
        <Col sm={12}>
          <div className="custom-control custom-checkbox">
            <input
              {...register('raising_child_person_taxation')}
              className="custom-control-input"
              id="raising_child_person_taxation"
              type="checkbox"
            />
            <label
              className="custom-control-label"
              htmlFor="raising_child_person_taxation"
            >
              {SearchLabels.RaisingChildPersonTaxation}
            </label>
          </div>
        </Col>
      </FormGroup>
    </Col>
  </Row>
)

export default RaisingChildPersonTaxation
