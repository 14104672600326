import React from 'react'
import { Control, Controller } from 'react-hook-form'
import Select, { OptionsOrGroups } from 'react-select'
import { FormGroup } from 'reactstrap'

type TMultiSelect = {
  name: string
  label?: string
  isDisabled?: boolean
  marginBottom?: number
  options: OptionsOrGroups<string, any>
  control: Control<any, object>
}

const MultiSelect: React.FC<TMultiSelect> = ({
  name,
  label,
  isDisabled,
  marginBottom,
  options,
  control,
}: TMultiSelect) => (
  <FormGroup className={marginBottom ? `mb-${marginBottom}` : ''}>
    {label && <h4>{label}</h4>}
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          closeMenuOnSelect={false}
          isMulti
          isDisabled={isDisabled}
          options={options}
          {...field}
        />
      )}
    />
  </FormGroup>
)

export default MultiSelect
