import { Labels, Routes } from 'common/enums'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Column } from 'react-table'
import ButtonRight from 'components/atoms/Button/Right'
import OriginCard from 'components/atoms/Common/OriginCard'
import { formatDate } from 'common/time'
import { LoggedIn } from 'common/types/logged_in'
import { LoggedInTable } from 'common/types/table/logged_in'
import { getLoggedIns, getLogsPage } from 'services/admin/logged_in'
import PaginationTable from 'components/atoms/Table/PaginationTable'
import { Pagination } from 'common/types/pagination'

const TableCard: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [pagination, setPagination] = useState<Pagination | null>(null)

  useEffect(() => {
    getLoggedIns(storeCache, setPagination)
  }, [storeCache])

  const previousPage = () =>
    getLogsPage(storeCache, pagination?.previous ?? null, setPagination, true)
  const nextPage = () =>
    getLogsPage(storeCache, pagination?.next ?? null, setPagination)

  const columns: Column<LoggedInTable>[] = useMemo(
    () => [
      { Header: Labels.ID, accessor: 'id', sortType: 'number' },
      { Header: 'ユーザ', accessor: 'member', sortType: 'basic' },
      { Header: 'ログイン日時', accessor: 'logged_in', sortType: 'basic' },
    ],
    []
  )
  const data: LoggedInTable[] = useMemo(
    () =>
      pagination
        ? pagination.results.map(({ id, logged_in, member_obj }: LoggedIn) => ({
            id,
            member: member_obj?.username ?? '',
            logged_in: formatDate(logged_in),
          }))
        : [],
    [pagination]
  )

  return (
    <OriginCard title="ログイン履歴">
      <ButtonRight
        history={history}
        nextPage={Routes.ManagementMenu}
        content={Labels.GoBackManagementMenu}
      />
      <PaginationTable
        columns={columns}
        data={data}
        pagination={pagination}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </OriginCard>
  )
}

export default TableCard
