import { Labels, SearchLabels } from 'common/enums'
import { HomeForm } from 'common/types/form/home'
import CancelOnlyModal from 'components/atoms/Modal/CancelOnlyModal'
import {
  generateHandbookTypes,
  generateReceipts,
} from 'components/atoms/Select/Options'
import React, { useState } from 'react'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'

type TChildrenDisorderDegree = {
  control: Control<HomeForm, object>
  register: UseFormRegister<HomeForm>
  otherChildrenFaultHtml: string
}

const ChildrenDisorderDegree: React.FC<TChildrenDisorderDegree> = ({
  control,
  register,
  otherChildrenFaultHtml,
}: TChildrenDisorderDegree) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Row className="mb-3">
      <Col sm="12">
        <h4>
          <i className="fas fa-square-full" />
          {SearchLabels.ChildrenDisorderDegree}
        </h4>
        <FormGroup className="d-flex align-items-center" row>
          <Col sm={2}>
            <Label>{Labels.PhysicalisabilityCertificate}</Label>
            <Controller
              name="physical_disability_certificate_child"
              control={control}
              render={({ field }) => (
                <Input type="select" {...field}>
                  {generateHandbookTypes()}
                </Input>
              )}
            />
          </Col>
          <Col sm={2}>
            <Label>{Labels.RaisingChildHandbook}</Label>
            <Controller
              name="raising_child_handbook_child"
              control={control}
              render={({ field }) => (
                <Input type="select" {...field}>
                  {generateHandbookTypes()}
                </Input>
              )}
            />
          </Col>
          <Col sm={3}>
            <Label>{Labels.MentalDisabilityCertificate}</Label>
            <Controller
              name="mental_disability_certificate_child"
              control={control}
              render={({ field }) => (
                <Input type="select" {...field}>
                  {generateHandbookTypes()}
                </Input>
              )}
            />
          </Col>
          <Col sm={3}>
            <Label>{SearchLabels.SpChildAllowanceSupportReceipt}</Label>
            <Controller
              name="sp_child_allowance_support_receipt"
              control={control}
              render={({ field }) => (
                <Input type="select" {...field}>
                  {generateReceipts()}
                </Input>
              )}
            />
          </Col>
          <Col sm={2}>
            <div className="custom-control custom-checkbox">
              <input
                {...register('other_children_fault')}
                className="custom-control-input"
                id="other_children_fault"
                type="checkbox"
              />
              <label
                className="custom-control-label"
                htmlFor="other_children_fault"
              >
                {Labels.Other}
              </label>
              <CancelOnlyModal
                content="?"
                modalElement={otherChildrenFaultHtml}
                marginX="1"
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(!isOpen)}
              />
            </div>
          </Col>
        </FormGroup>
      </Col>
    </Row>
  )
}

export default ChildrenDisorderDegree
