import React from 'react'
import { Control, Controller } from 'react-hook-form'
import Select, { OptionsOrGroups } from 'react-select'
import { FormGroup } from 'reactstrap'

type TSingleSelect = {
  name: string
  label?: string
  isDisabled?: boolean
  marginBottom?: number
  options: OptionsOrGroups<string, any>
  control: Control<any, object>
}

const SingleSelect: React.FC<TSingleSelect> = ({
  name,
  label,
  marginBottom,
  options,
  control,
}: TSingleSelect) => (
  <FormGroup className={marginBottom ? `mb-${marginBottom}` : ''}>
    {label && <h4>{label}</h4>}
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select isSearchable isClearable options={options} {...field} />
      )}
    />
  </FormGroup>
)

export default SingleSelect
