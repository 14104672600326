import { Labels, Routes } from 'common/enums'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import OriginCard from 'components/atoms/Common/OriginCard'
import { deleteConditionPattern } from 'services/admin/condition'
import RightDoubleButton from 'components/atoms/Button/RightDoubleButton'
import Condition from 'components/molecules/Admin/Condition/Condition'
import { convertConditionTitle } from 'common/utils'

type TTableCard = {
  isConditionGroup: boolean
}

const TableCard: React.FC<TTableCard> = ({ isConditionGroup }: TTableCard) => {
  const { storeCache } = useContext(AuthContext)
  const { push } = useHistory()

  const conditionList = isConditionGroup
    ? storeCache.conditionGroups
    : storeCache.conditionPatterns

  return (
    <OriginCard title={`${convertConditionTitle(isConditionGroup)}管理`}>
      <RightDoubleButton
        leftOnClick={() => push(Routes.ManagementMenu)}
        rightOnClick={() =>
          push(
            isConditionGroup
              ? Routes.ConditionGroupCreate
              : Routes.ConditionPatternCreate
          )
        }
        leftContent={Labels.GoBackManagementMenu}
        rightContent={Labels.Create}
      />
      {conditionList.map((cl) => (
        <Condition
          storeCache={storeCache}
          isConditionGroup={isConditionGroup}
          condition={cl}
          onEdit={() =>
            push(
              isConditionGroup
                ? Routes.ConditionGroupEdit
                : Routes.ConditionPatternEdit,
              cl
            )
          }
          onRemove={() =>
            deleteConditionPattern(storeCache, isConditionGroup, cl)
          }
        />
      ))}
    </OriginCard>
  )
}

export default TableCard
