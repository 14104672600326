import { ButtonColor, Labels, Routes } from 'common/enums'
import { TagTable } from 'common/types/table/tag'
import { Tag } from 'common/types/tag'
import ButtonRight from 'components/atoms/Button/Right'
import SmallButton from 'components/atoms/Button/SmallButton'
import OriginCard from 'components/atoms/Common/OriginCard'
import StandardTable from 'components/atoms/Table/StandardTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Column } from 'react-table'
import { deleteTag } from 'services/user/tag'

const TableCard: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const columns: Column<TagTable>[] = useMemo(
    () => [
      { Header: 'ID', accessor: 'id', sortType: 'basic' },
      { Header: 'タグ名', accessor: 'name' },
      { Header: '並び順', accessor: 'sort_num', sortType: 'basic' },
      { Header: '編集', accessor: 'edit', disableSortBy: true },
    ],
    []
  )
  const data: TagTable[] = useMemo(
    () =>
      storeCache.tags.map((tag: Tag) => {
        return {
          id: tag.id,
          name: tag.name,
          sort_num: tag.sort_num,
          edit: (
            <>
              <SmallButton
                color={ButtonColor.INFO}
                content={Labels.Edit}
                onClick={() => history.push(Routes.TagEdit, tag)}
              />
              <SmallButton
                color={ButtonColor.DANGER}
                content={Labels.Delete}
                onClick={() => deleteTag(storeCache, tag)}
              />
            </>
          ),
        }
      }),
    [storeCache, history]
  )

  return (
    <OriginCard title="タグ">
      <ButtonRight
        history={history}
        nextPage={Routes.TagCreate}
        content={Labels.Create}
      />
      <StandardTable columns={columns} data={data} />
    </OriginCard>
  )
}

export default TableCard
