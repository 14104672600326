import { ButtonColor, Labels, Routes } from 'common/enums'
import { MemberTable } from 'common/types/table/member'
import { Member } from 'common/types/member'
import StandardTable from 'components/atoms/Table/StandardTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Column } from 'react-table'
import { deleteMember, getMembers } from 'services/admin/member'
import SmallButton from 'components/atoms/Button/SmallButton'
import RightDoubleButton from 'components/atoms/Button/RightDoubleButton'
import OriginCard from 'components/atoms/Common/OriginCard'

const TableCard: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const { push } = useHistory()
  const [members, setMembers] = useState<Member[]>([])

  useEffect(() => {
    getMembers(storeCache.accesstoken, setMembers)
  }, [storeCache.accesstoken])

  const columns: Column<MemberTable>[] = useMemo(
    () => [
      { Header: Labels.ID, accessor: 'id', sortType: 'number' },
      {
        Header: Labels.MemberUsername,
        accessor: 'username',
        sortType: 'basic',
      },
      { Header: Labels.Email, accessor: 'email', sortType: 'basic' },
      {
        Header: Labels.Organization,
        accessor: 'organization',
        sortType: 'basic',
      },
      { Header: Labels.Area, accessor: 'area', sortType: 'basic' },
      { Header: Labels.Permission, accessor: 'permission', sortType: 'basic' },
      { Header: Labels.Edit, accessor: 'edit', disableSortBy: true },
    ],
    []
  )
  const data: MemberTable[] = useMemo(
    () =>
      members.map((member: Member) => {
        return {
          id: member.id,
          username: member.username,
          email: member.email,
          organization: member.organization_obj!?.name,
          area: member.area_obj!?.name,
          permission: member.permission_obj!?.name,
          edit: (
            <>
              <SmallButton
                color={ButtonColor.INFO}
                content={Labels.Edit}
                onClick={() => push(Routes.MemberEdit, member)}
              />
              <SmallButton
                color={ButtonColor.DANGER}
                content={Labels.Delete}
                onClick={() => deleteMember(storeCache, member, setMembers)}
              />
            </>
          ),
        }
      }),
    [members, push, storeCache]
  )

  return (
    <OriginCard title="メンバー管理">
      <RightDoubleButton
        leftOnClick={() => push(Routes.ManagementMenu)}
        rightOnClick={() => push(Routes.MemberCreate)}
        leftContent={Labels.GoBackManagementMenu}
        rightContent={Labels.Create}
      />
      <StandardTable columns={columns} data={data} />
    </OriginCard>
  )
}

export default TableCard
