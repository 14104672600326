import { IStoreCache } from 'common/types/auth_provider'
import { SystemMessage } from 'common/types/system_message'
import { getErrorMessage } from 'common/utils'
import { reloadCachedSystemMessage } from 'providers/AuthProvider'
import { update } from 'repositories/system_message'
import { alertService } from 'services/alert'

/**
 * システム文言管理を作成 or 更新する
 */
export const saveSystemMessage = async (
  storeCache: IStoreCache,
  data: SystemMessage
): Promise<void> => {
  try {
    await update(storeCache.accesstoken, data)
    await reloadCachedSystemMessage(storeCache)

    alertService.show(true, 'システム文言管理の保存が完了しました。')
  } catch (error) {
    getErrorMessage(error)
  }
}
