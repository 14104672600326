import { ButtonColor, Labels, Routes } from 'common/enums'
import StandardTable from 'components/atoms/Table/StandardTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Column } from 'react-table'
import { Organization } from 'common/types/organization'
import { OrganizationTable } from 'common/types/table/organization'
import RightDoubleButton from 'components/atoms/Button/RightDoubleButton'
import SmallButton from 'components/atoms/Button/SmallButton'
import { deleteOrganization } from 'services/admin/organization'
import OriginCard from 'components/atoms/Common/OriginCard'

const TableCard: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const { push } = useHistory()

  const columns: Column<OrganizationTable>[] = useMemo(
    () => [
      { Header: Labels.ID, accessor: 'id', sortType: 'number' },
      { Header: Labels.OrganizationName, accessor: 'name', sortType: 'basic' },
      { Header: '編集', accessor: 'edit', disableSortBy: true },
    ],
    []
  )
  const data: OrganizationTable[] = useMemo(
    () =>
      storeCache.organizations.map((organization: Organization) => {
        return {
          id: organization.id,
          name: organization.name,
          edit: (
            <>
              <SmallButton
                color={ButtonColor.INFO}
                content={Labels.Edit}
                onClick={() => push(Routes.OrganizationEdit, organization)}
              />
              <SmallButton
                color={ButtonColor.DANGER}
                content={Labels.Delete}
                onClick={() => deleteOrganization(storeCache, organization)}
              />
            </>
          ),
        }
      }),
    [push, storeCache]
  )

  return (
    <OriginCard title="団体管理">
      <RightDoubleButton
        leftOnClick={() => push(Routes.ManagementMenu)}
        rightOnClick={() => push(Routes.OrganizationCreate)}
        leftContent={Labels.GoBackManagementMenu}
        rightContent={Labels.Create}
      />
      <StandardTable columns={columns} data={data} />
    </OriginCard>
  )
}

export default TableCard
