import { getOrGenerateBadgeStr } from 'common/utils'
import Nomal from 'components/atoms/Badge/Nomal'
import React from 'react'

/**
 * フォーマットしてから行うバッジ生成処理
 */
export const NomalBadges = (
  array: string[],
  color: string,
  secondColor: string = color
): JSX.Element[] =>
  array.map((value: any, key: number) => (
    <Nomal
      key={key}
      color={key % 2 === 0 ? color : secondColor}
      content={value}
    />
  ))

/**
 * オブジェクト専用バッジ生成処理
 */
export const BadgesForObject = (
  obj: any,
  keyNames: (key: string) => string,
  color: string,
  secondColor: string = color
): JSX.Element[] => {
  if (!obj) return []
  return Object.keys(obj).map((key: string) => (
    <div key={key} className="my-1">
      <Nomal color={color} content={keyNames(key)} />
      <span>:</span>
      {Array.isArray(obj[key]) ? (
        NomalBadges(obj[key], secondColor, secondColor)
      ) : (
        <Nomal color={secondColor} content={getOrGenerateBadgeStr(obj[key])} />
      )}
    </div>
  ))
}

/**
 * JSON配列専用バッジ生成処理
 */
export const BadgesForJSONArr = (
  jsonArr: any[],
  keyNames: (key: string) => string,
  color: string,
  secondColor: string = color
): JSX.Element[] =>
  jsonArr.map((json: any, key: number) => (
    <div key={key} className="my-1">
      {Object.keys(json).map((childKey: string) => (
        <span className="mx-1">
          <Nomal color={color} content={keyNames(childKey)} />
          <span>:</span>
          {Array.isArray(json[childKey]) ? (
            NomalBadges(json[childKey], secondColor, secondColor)
          ) : (
            <Nomal
              color={secondColor}
              content={getOrGenerateBadgeStr(json[childKey])}
            />
          )}
        </span>
      ))}
    </div>
  ))
