import { Routes, AdminViewType } from 'common/enums'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router'
import { alertService } from 'services/alert'
import { isNormalUserOrLess } from 'services/auth'
import Area from 'template/Admin/Area'
import AreaForm from 'template/Admin/AreaForm'
import ConditionForm from 'template/Admin/ConditionForm'
import ConditionList from 'template/Admin/ConditionList'
import Log from 'template/Admin/Log'
import LoggedIn from 'template/Admin/LoggedIn'
import ManagementMenu from 'template/Admin/ManagementMenu'
import Member from 'template/Admin/Member'
import MemberForm from 'template/Admin/MemberForm'
import Organization from 'template/Admin/Organization'
import OrganizationForm from 'template/Admin/OrganizationForm'
import Permission from 'template/Admin/Permission'
import SystemMessageForm from 'template/Admin/SystemMessageForm'

const Admin: React.FC = () => {
  const { user, accesstoken, isLoading } = useContext(AuthContext).storeCache
  const { replace } = useHistory()

  const [viewType, setViewType] = useState<AdminViewType>(AdminViewType.LOADING)

  useEffect(() => {
    if (isLoading) setViewType(AdminViewType.LOADING)
    else if (!accesstoken) setViewType(AdminViewType.LOGIN)
    else if (isNormalUserOrLess(user)) setViewType(AdminViewType.USER)
    else setViewType(AdminViewType.VISIBLE)
  }, [user, accesstoken, isLoading])

  switch (viewType) {
    case AdminViewType.LOGIN:
      alertService.show(
        false,
        '認証が正しくできていません。ログインしてください。'
      )
      replace(Routes.Login)
      return null
    case AdminViewType.LOADING:
      return <FullLoadingSpinner />
    case AdminViewType.USER:
      alertService.show(false, 'この画面にはアクセスできません。')
      replace(Routes.Home)
      return null
    case AdminViewType.VISIBLE:
      return routeSwitch()
  }
}

const routeSwitch = (): JSX.Element => (
  <>
    <Switch>
      <Route
        path={Routes.ManagementMenu}
        component={() => <ManagementMenu />}
        exact
      />
      <Route path={Routes.Member} component={() => <Member />} exact />
      <Route
        path={[Routes.MemberCreate, Routes.MemberEdit]}
        component={() => <MemberForm />}
        exact
      />
      <Route
        path={Routes.Organization}
        component={() => <Organization />}
        exact
      />
      <Route
        path={[Routes.OrganizationCreate, Routes.OrganizationEdit]}
        component={() => <OrganizationForm />}
        exact
      />
      <Route path={Routes.Area} component={() => <Area />} exact />
      <Route
        path={[Routes.AreaCreate, Routes.AreaEdit]}
        component={() => <AreaForm />}
        exact
      />
      <Route path={Routes.Permission} component={() => <Permission />} exact />
      <Route path={Routes.Log} component={() => <Log />} exact />
      <Route path={Routes.LoggedIn} component={() => <LoggedIn />} exact />
      <Route
        path={Routes.SystemMessageForm}
        component={() => <SystemMessageForm />}
        exact
      />
      <Route
        path={[Routes.ConditionGroup, Routes.ConditionPattern]}
        component={() => <ConditionList />}
        exact
      />
      <Route
        path={[
          Routes.ConditionGroupCreate,
          Routes.ConditionGroupEdit,
          Routes.ConditionPatternCreate,
          Routes.ConditionPatternEdit,
        ]}
        component={() => <ConditionForm />}
        exact
      />
    </Switch>
  </>
)

export default Admin
