import { destroy, get, patch, post } from 'common/fetch'
import { Area } from 'common/types/area'
import { AreaForm } from 'common/types/form/area'

const API_BASE_URL = '/area/'

/**
 * 地域を全て取得する
 */
export const fetch = async (accesstoken: string): Promise<Area[]> =>
  await get<Area[]>(accesstoken, API_BASE_URL)

/**
 * 地域を新規登録する
 */
export const create = async (
  accesstoken: string,
  data: AreaForm
): Promise<Area> => await post<Area>(accesstoken, API_BASE_URL, data)

/**
 * 地域を更新する
 */
export const update = async (
  accesstoken: string,
  data: AreaForm,
  id: number
): Promise<Area> =>
  await patch<Area>(accesstoken, `${API_BASE_URL}${id}/`, data)

/**
 * 地域を削除する
 */
export const remove = async (
  accesstoken: string,
  id: number,
  member_id: number
): Promise<void> =>
  await destroy<void>(accesstoken, `${API_BASE_URL}${id}/`, { member_id })
