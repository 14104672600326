import { destroy, get, patch, post } from 'common/fetch'
import { Case } from 'common/types/case'
import { CaseForm } from 'common/types/form/case'
import { SearchCase } from 'common/types/form/home'

const API_BASE_URL = '/case/'

/**
 * 事例を全て取得する
 */
export const fetch = async (
  accesstoken: string,
  data?: SearchCase
): Promise<Case[]> => await get<Case[]>(accesstoken, API_BASE_URL, data)

/**
 * 事例を新規登録する
 */
export const create = async (
  accesstoken: string,
  data: CaseForm
): Promise<Case> => await post<Case>(accesstoken, API_BASE_URL, data)

/**
 * 事例を更新する
 */
export const update = async (
  accesstoken: string,
  data: CaseForm,
  id: number
): Promise<Case> =>
  await patch<Case>(accesstoken, `${API_BASE_URL}${id}/`, data)

/**
 * 事例を削除する
 */
export const remove = async (
  accesstoken: string,
  id: number,
  member_id: number
): Promise<void> =>
  await destroy<void>(accesstoken, `${API_BASE_URL}${id}/`, { member_id })
