import { Routes } from 'common/enums'
import ItemAction, { ItemActionType } from 'components/atoms/Nav/ItemAction'
import ItemLink from 'components/atoms/Nav/ItemLink'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { Collapse, Container, Nav, Navbar, NavbarToggler } from 'reactstrap'
import { isNormalUserOrLess } from 'services/auth'
import 'styles/sidebar.scss'

const Sidebar: React.FC = () => {
  const { storeCache } = useContext(AuthContext)

  const [collapseOpen, setCollapseOpen] = useState<boolean>(false)

  const isNotNormalUser = !isNormalUserOrLess(storeCache.user)

  const sideBarToggle = () => setCollapseOpen(!collapseOpen)

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white nav-container admin-navbar"
      expand="md"
      id="sidenav-main"
    >
      <NavbarToggler onClick={sideBarToggle} className="mr-2" />
      <Container fluid>
        <Collapse navbar isOpen={collapseOpen}>
          <h6 className="navbar-heading text-muted">
            <i className="fas fa-square-full" /> MENU
          </h6>
          <Nav navbar>
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.Home}
              content="HOME"
            />
            {isNotNormalUser && (
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.Tag}
                content="タグ"
              />
            )}
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.Case}
              content="事例一覧"
            />
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.SupportSystem}
              content="支援制度"
            />
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.SupportOrganization}
              content="支援団体"
            />
            {isNotNormalUser && (
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.ManagementMenu}
                content="管理メニュー"
              />
            )}
            <ItemAction action={ItemActionType.LOGOUT} />
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default Sidebar
