import { Routes } from 'common/enums'
import { Organization } from 'common/types/organization'
import FormCard from 'components/organisms/Admin/OrganizationForm/FormCard'
import React from 'react'
import { useLocation } from 'react-router'

const OrganizationForm: React.FC = () => {
  const { pathname, state } = useLocation<Organization>()

  const isCreate = pathname === Routes.OrganizationCreate
  const organization = state

  return <FormCard isCreate={isCreate} organization={organization} />
}

export default OrganizationForm
