import { Labels, Routes } from 'common/enums'
import { OrganizationForm } from 'common/types/form/organization'
import { Organization } from 'common/types/organization'
import {
  createMaxLengthMessage,
  createRequiredMessage,
  getTitle,
} from 'common/utils'
import OnSubmitButton from 'components/atoms/Button/OnSubmitButton'
import ButtonRight from 'components/atoms/Button/Right'
import OriginCard from 'components/atoms/Common/OriginCard'
import Feedback from 'components/atoms/Feedback/Feedback'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Form, FormGroup, Input } from 'reactstrap'
import { saveOrganization } from 'services/admin/organization'

type TFormCard = {
  isCreate: boolean
  organization: Organization
}

const FormCard: React.FC<TFormCard> = ({
  isCreate,
  organization,
}: TFormCard) => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const defaultValues = isCreate
    ? { name: '', member_id: storeCache.user!.id }
    : { name: organization.name, member_id: storeCache.user!.id }
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<OrganizationForm>({ defaultValues })

  const save = (data: OrganizationForm) =>
    saveOrganization(
      storeCache,
      history,
      isCreate,
      data,
      isCreate ? null : organization.id
    )

  return (
    <OriginCard title={`団体${getTitle(isCreate, false)}`}>
      <ButtonRight
        history={history}
        nextPage={Routes.Organization}
        content={Labels.GoBack}
      />
      <Form role="form" onSubmit={handleSubmit(save)}>
        <h4>{Labels.OrganizationName}</h4>
        <FormGroup className="mb-5">
          <Controller
            name="name"
            control={control}
            rules={{
              required: createRequiredMessage(Labels.OrganizationName),
              maxLength: {
                value: 255,
                message: createMaxLengthMessage(Labels.OrganizationName, 255),
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                list="name"
                className={errors.name && 'is-invalid'}
                {...field}
              />
            )}
          />
          <datalist id="name">
            {storeCache.organizations.map(({ name }, i) => (
              <option key={i} value={name} />
            ))}
          </datalist>
          <Feedback error={errors.name} />
        </FormGroup>
        <OnSubmitButton content={Labels.Save} />
      </Form>
    </OriginCard>
  )
}

export default FormCard
